import { InjectionToken } from '@angular/core';
import { ClaimAdjustmentStatus } from './claim-adjustment-status.enum';
import { InternalAdministrationStrategy } from 'app/core/services/management-tracking-card/internal-administration.strategy';
import { LenderOwnersStrategy } from 'app/core/services/management-tracking-card/lender-owners.strategy';
import { ManagementTrackingCardStrategy } from 'app/core/services/management-tracking-card/management-tracking-card.strategy';
import { InitialAnalysisStrategy } from 'app/core/services/management-tracking-card/initial-analysis.strategy';

export const internalAdministrationStrategyToken = new InjectionToken<ManagementTrackingCardStrategy>(
  typeof InternalAdministrationStrategy
);
export const lenderOwnersStrategyStrategyToken = new InjectionToken<ManagementTrackingCardStrategy>(typeof LenderOwnersStrategy);

export const initialAnalysisStrategyStrategyToken = new InjectionToken<ManagementTrackingCardStrategy>(typeof InitialAnalysisStrategy);

export const ManagementTrackingCardMap = {
  [ClaimAdjustmentStatus.Default]: {
    getCard: () => ({
      hasActionable: true
    })
  },
  [ClaimAdjustmentStatus.InitialAnalysis]: initialAnalysisStrategyStrategyToken,
  [ClaimAdjustmentStatus.InternalAdministration]: internalAdministrationStrategyToken,
  [ClaimAdjustmentStatus.LenderOwners]: lenderOwnersStrategyStrategyToken
};
