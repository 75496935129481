<div class="sc-header-info">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="sc-header-info__user">
                    <strong>Username: </strong><span>{{ userInfo.userName }}</span>
                </div>
            </div>
            <div class="col-md-4 col-sm-12">
                    <div class="sc-header-info__environment">
                        <strong>Environment: </strong><span>{{ _environment.type.name }}</span>
                    </div>
                </div>
            <div class="col-md-4 col-sm-12">
                <div class="sc-header-info__roles">
                    <strong>Role: </strong><span>{{ userInfo.userRole }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
