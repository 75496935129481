<sc-modal class="sc-versions-modal" id="versionsModal" [options]="modalVersionsOptions">
    <div class="text-left">
        <p><strong>Version:</strong> {{version}}</p>
        <p><strong>APIS URL:</strong> {{apiBaseUrl}}</p>
        <p><strong>Dependencies:</strong></p>
        <ul>
          <li *ngFor="let dependency of dependencies">
            <strong>{{dependency.dependency}}: </strong>{{dependency.version}}
          </li>
        </ul>
    </div>
    <sc-button (onClick)="closeVersionsModal()">Cerrar</sc-button>
</sc-modal>
