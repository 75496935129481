import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'scInverseCurrency',
    pure: false
})
export class ScInverseCurrencyPipe implements PipeTransform {
    transform(val: any): any {
        return this.toRaw(val);
    }

    toRaw(val: any): any {
        val = val.toString().replace(/\./g, '').replace(',', '.');
        val = parseFloat(val);
        val = this.toFixed(val);

        return val;
    }

    toFixed(val: any): any {

        if (val.toString().indexOf('.') > 0) {
            val = Number(val).toFixed(2);
        }

        return val;
    }
}
