import { Payment } from './payment.model';

export class Exposition {
  adminEmail: string;
  adminName: string;
  claimOrder: number;
  name: string;
  payments: Array<Payment>;
  stage?: string;
  step?: string;
  status?: string;
  state?: string;
}
