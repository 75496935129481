import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'scFirstLetters'})

export class ScFirstLetters implements PipeTransform {
  transform(value: string): string {
    const wordsArray: string[] = value.split(' ');
    const lowerWordsArray = wordsArray.map( (el, index) => index === 0 ? el : el.toLowerCase() );
    return lowerWordsArray.join(' ');
  }
}
