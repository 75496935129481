import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scReverse',
    pure: false
})
export class ScReversePipe implements PipeTransform {
  transform(values): Array<any> {
    if (values) {
        return values.reverse();
    }
  }
}
