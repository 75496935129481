<div class="sc-footer" *ngIf="hasPlatformNewsAccess || hasLoginAgreementServiceAccess">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center">
            <sc-button class="sc-footer__service-agreement-btn" size="small" type="link" (onClick)="openNews()" *ngIf="hasPlatformNewsAccess">Novedades en la plataforma</sc-button>
            <span class="align-text-bottom px-1" *ngIf="hasPlatformNewsAccess && hasLoginAgreementServiceAccess">.</span>
            <sc-button class="sc-footer__service-agreement-btn" size="small" type="link" (onClick)="openServiceAgreementModal()" *ngIf="hasLoginAgreementServiceAccess">Acuerdo de Servicio</sc-button>
            <span class="align-text-bottom px-1" *ngIf="hasPlatformNewsAccess && hasLoginAgreementServiceAccess">.</span>
            <sc-button class="sc-footer__service-agreement-btn" size="small" type="link" *ngIf="hasLoginAgreementServiceAccess" (onClick)='openDownloadBaremo()'>Baremos de daños</sc-button>
        </div>
      </div>
    </div>
  </div>
</div>

<sc-news-modal></sc-news-modal>
<sc-service-agreement-modal></sc-service-agreement-modal>
<sc-download-baremo-modal></sc-download-baremo-modal>
