import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiCoreComponentsModule, ScModalDialogService } from '@sc/claim-components';

// Components
import { ScNewsModalComponent } from './sc-news-modal.component';

// Services
import { AuthenticationService, SiniestrosService } from 'app/core/services';
@NgModule({
  imports: [
    CommonModule,
    UiCoreComponentsModule
  ],
  exports: [
    ScNewsModalComponent
  ],
  declarations: [
    ScNewsModalComponent
  ],
  providers: [
    ScModalDialogService,
    AuthenticationService,
    SiniestrosService
  ]
})
export class ScNewsModalModule { }
