import { UntypedFormControl } from '@angular/forms';

export const errorMessages = {
    'email': (params) => params.message
};

export const dateErrorMessages = {
    minDate: () => 'Fecha menor a fecha mínima',
    maxDate: () => 'Fecha mayor a fecha máxima',
    validDate: () => ''
  };

export const emailValidator = (c: UntypedFormControl) => {

    /* tslint:disable */
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    /* tslint:enable */

    const isValid = c.value && c.value.length > 0 && regex.test(String(c.value).toLowerCase());

    const message = {
        'email': {
            'message': 'Ingrese un email válido'
        }
    };

    return isValid ? null : message;
};
