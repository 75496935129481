export class SparePartsView {
  title: string;
  subTitle: string;
  status: number;
  receptionDate: Date;

  constructor() {
    this.title = '';
    this.subTitle = '';
    this.status = null;
    this.receptionDate = new Date();
  }
}
