import { Injectable } from '@angular/core';
import { UntypedFormGroup, AbstractControl } from '@angular/forms';

@Injectable()

export class ScDateHelper {

    dbDate(d: Date): string {
        return d.toISOString();
    }

    addDays(date, days) {
        date.setDate(date.getUTCDate() + parseInt(days, 10));
        return date;
    }

    diffDates(date1, date2) {
        date1 = (date1 instanceof Date) ? date1 : new Date(date1);
        date2 = (date2 instanceof Date) ? date2 : new Date(date2);

        // Get 1 day in milliseconds
        const one_day = 1000 * 60 * 60 * 24;

        // Convert both dates to milliseconds
        const date1_ms = date1.getTime();
        const date2_ms = date2.getTime();

        // Calculate the difference in milliseconds
        const difference_ms = date2_ms - date1_ms;

        // Convert back to days and return
        return Math.abs(Math.round(difference_ms / one_day));
    }

    dateValidator = (formGroup: UntypedFormGroup, control: AbstractControl, rangeStart, rangeEnd = null): { [key: string]: boolean } | null => {
        if (formGroup) {
            const controlValue = this.formatDate(control.value);
            const startDate = this.formatDate(rangeStart);
            const endDate = this.formatDate(rangeEnd);

            if (control.value !== undefined && (isNaN(control.value) && control.value instanceof Date)) {
              return { 'validDate': true };
            }

            if (control.value !== undefined && (isNaN(control.value) || controlValue < startDate)) {
                return { 'minDate': true };
            }

            if (rangeEnd && control.value !== undefined && (isNaN(control.value) || controlValue > endDate)) {
              return { 'maxDate': true };
            }
        }

        return null;
    }

    private formatDate(date) {
        return date ?
            new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()) : date;
    }
}
