<sc-modal-dialog class="sc-platform-news-modal" id="newsModal" [scModalDialogConfig]="scNewsModalConfig" (onOpen)="_onOpenModal($event)">
    <div class="sc-platform-news-modal__loading" *ngIf="loading">
        <sc-icon icon="fa-duotone fa-spinner-third"></sc-icon>
    </div>
    <section class="sc-platform-news-modal__main" [ngClass]="{'sc-platform-news-modal__main-loading': loading}">
        <div class="sc-platform-news-modal__main__content">
            <iframe title="reclamos nuevos" class="sc-platform-news-modal__iframe" [attr.src]="safeResourceUrl" (load)="_onIframeLoad($event)"></iframe>
        </div>
    </section>
</sc-modal-dialog>
