import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// External Libraries
import { JwtHelperService } from '@auth0/angular-jwt';

// Helpers
import { ApiClient } from 'app/core/helpers';

// Environments
import { environment } from 'environments/environment';

@Injectable()
export class BudgetService {

    public jwtHelperService: JwtHelperService;

    constructor(
        private httpClient: HttpClient,
        private api: ApiClient
    ) {
        this.jwtHelperService = new JwtHelperService();
    }

    getTemplate() {
        return this.api.get(environment.apiClaim + '/api/pricequote/template');
    }

    getBudget(activityId) {
        return this.api.get(environment.apiClaim + '/api/pricequote/' + activityId);
    }

    getBudgetSparesList(searchText = '', vehicleTypeCategory = '') {
        return this.api.get(
            environment.apiClaim + '/api/pricequote/autocomplete?searchText=' + searchText + '&vehicleTypeCategory=' + vehicleTypeCategory
        );
    }
}
