import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'upper-filter'
})
export class ScUpperWithFilterPipe implements PipeTransform {
    transform(value: string, filter: string): string {
        return value !== filter ? value.toUpperCase() : '';
    }
}
