import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scPrefix'
})
export class ScPrefixPipe implements PipeTransform {
    transform(text: string, prefix: string): string {
        return this.setFormat(text, prefix);
    }

    setFormat(text, prefix) {
        if (!text) { return prefix; }

        return prefix + text.replace(/\s/g, '').replace(/101-/g, '');
    }
}
