import { SpecialistVendor } from "./specialist-vendor.model";
import { SparePart } from 'app/shared/models/spare-part';

export class ServiceOrdersByClaimNumberResponse {
  public serviceOrders: Array<ServiceOrder>;
  public specialistVendors: Array<SpecialistVendor>;
}

export class ServiceOrder {
  public orderNumber: number;
  public vendorFullName: string;
  public acceptanceDate: Date | string;
  public estimatedDeliveryDates: Date | string;
  public status: string;
   public spareParts: SparePart [];
}
