import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { ScModalDialogConfig } from '@sc/claim-components';

// Services
import { SiniestrosService } from 'app/core/services/siniestros.service';

// Models
import { Claim } from 'app/shared/models';

@Component({
  selector: 'sc-contact-info-modal',
  templateUrl: './sc-contact-info-modal.component.html',
  styleUrls: ['./sc-contact-info-modal.component.scss']
})
export class ScContactInfoModalComponent implements OnInit {

  private _siniestro = new BehaviorSubject(new Claim());

  @Input() set siniestro(value: Claim) {
    this._siniestro.next(value);
  }

  get siniestro() {
    return this._siniestro.getValue();
  }

  // Modal Config
  public contactInfoModalOptions: ScModalDialogConfig;
  public modalLoading: boolean;
  public modalScreen: string;

  // Conact edit form
  private contactEditForm: UntypedFormGroup;
  private nameCustomMessage: any = { required: () => 'Ingresá un nombre' };
  private phoneNumberCustomMessage: any = { required: () => 'Ingresá un teléfono' };
  private emailCustomMessage: any = { required: () => 'Ingresá un email', email: () => 'Ingresá un email válido' };

  // Contact Info
  public contactsTop: Array<any>;
  public contactsBottom: Array<any>;
  public contactsModalOptions: any;
  public contactsSectionOpen: boolean;
  public contactsToDisplayTop: Array<any>;
  public contactsToDisplayBottom: Array<any>;

  public errorMessage: string;

  constructor(
    private siniestrosService: SiniestrosService
  ) {
    // Modal Config
    this.modalScreen = 'mainScreen';
    this.modalLoading = false;
    this.contactInfoModalOptions = {
      size: 'large',
      showDefaultFooter: false,
      showDefaultHeader: true,
      title: 'Datos de contacto',
      disableScroll: true,
      height: '650'
    };
    // Contact Info
    this.contactsTop = [];
    this.contactsBottom = [];
    this.contactsToDisplayTop = [
      { entity: 'insured', label: 'Asegurado' }
    ];
    this.contactsToDisplayBottom = [
      { entity: 'administrator', label: 'Administrador' },
      { entity: 'claimAdjuster', label: 'Perito' },
      { entity: 'producer', label: 'PAS' }
    ];
  }

  ngOnInit() {
    this.setDataFromSiniestro();
    this.contactEditForm = new UntypedFormGroup({
      'name': new UntypedFormControl('', [Validators.required]),
      'phoneNumber': new UntypedFormControl('', {}),
      'email': new UntypedFormControl('', [Validators.email])
    });
  }

  setDataFromSiniestro() {
    this._siniestro.subscribe((res) => {
      if (res.activityId) {
        this.contactsTop = this.getContactsToDisplayTop();
        this.contactsBottom = this.getContactsToDisplayBottom();
      }
    });
  }

  changeScreen(screen: string): void {
    if (screen === 'contactEditScreen') {

      // Modify contactInfoModalOptions
      this.contactInfoModalOptions.size = 'medium';
      this.contactInfoModalOptions.showDefaultFooter = false;
      this.contactInfoModalOptions.showDefaultHeader = true;
      this.contactInfoModalOptions.title = 'Editar datos de contacto';

      if (this.siniestro.contact) {
        this.contactEditForm.setValue({
          name: this.siniestro.contact.name,
          phoneNumber: this.siniestro.contact.phoneNumber,
          email: this.siniestro.contact.email
        });
      }
    } else if (screen === 'mainScreen') {
      this.errorMessage = null;
      // Modify contactInfoModalOptions
      this.contactInfoModalOptions.size = 'large';
      this.contactInfoModalOptions.showDefaultFooter = false;
      this.contactInfoModalOptions.showDefaultHeader = true;
      this.contactInfoModalOptions.title = 'Datos de contacto';
    }
    this.modalScreen = screen;
  }

  getContactsToDisplayTop() {
    return this.contactsToDisplayTop.map((contactToDisplay) => {
      const contact = this.siniestro[contactToDisplay.entity];
      return (contact) ? Object.assign({ label: contactToDisplay.label }, contact) : null;
    }).filter((contact) => (contact.businessName || (contact.firstName && contact.lastName)));
  }

  getContactsToDisplayBottom() {
    return this.contactsToDisplayBottom.map((contactToDisplay) => {
      const contact = this.siniestro[contactToDisplay.entity];
      return (contact) ? Object.assign({ label: contactToDisplay.label }, contact) : null;
    }).filter((contact) => (contact.businessName || contact.lawFirm || (contact.firstName && contact.lastName)));
  }

  updateContactInfo() {
    this.modalLoading = this.contactInfoModalOptions.disableClose = true;
    this.contactEditForm.disable();
    this.siniestrosService.updateActivityContact(this.siniestro.activityId, this.contactEditForm.value).subscribe({
      next: (res) => {
        this.siniestro = res;
        this.changeScreen('mainScreen');
        this.contactEditForm.enable();
        this.modalLoading = this.contactInfoModalOptions.disableClose = false;
      },
      error: () => {
        this.errorMessage = 'Ha ocurrido un error.';
        this.modalLoading = this.contactInfoModalOptions.disableClose = false;
        this.contactEditForm.enable();
      }
    });
  }

  cancelContactEdition() {
    this.contactEditForm.reset();
    this.errorMessage = null;
    this.changeScreen('mainScreen');
  }

  closeModal() {
    setTimeout(() => {
      this.cancelContactEdition();
    }, 200); // After the .2s of duration of the animation
  }

}
