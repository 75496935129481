import { ManagementTracking } from 'app/shared/models';
import { ClaimDetailCardItem } from 'app/shared/models/claimDetail';
import { ManagementTrackingCard } from 'app/shared/models/management-tracking-card.interface';

export abstract class ManagementTrackingCardStrategy {
  abstract getCard(managementTracking: ManagementTracking): ManagementTrackingCard;

  protected getLastItem(managementTracking: ManagementTracking) {
    return ClaimDetailCardItem.create('Última gestión', managementTracking.lastManagement);
  }
}
