export class ClaimDetailConfig {
  columnClass: string;
  cards: Array<ClaimDetailCard>;
  claimItems: Array<ClaimItem>;

  private constructor(columnClass: string, cards: any[], claimItems: Array<ClaimItem>) {
    this.columnClass = columnClass;
    this.cards = cards;
    this.claimItems = claimItems;
  }

  static create(columnClass: string, cards: any[], ...params: Array<ClaimItem>): ClaimDetailConfig {
    return new ClaimDetailConfig(columnClass, cards, params);
  }
}

export class ClaimDetailCard {
  title: string;
  subtitle: string;
  items: Array<ClaimDetailCardItem>;
  lastItem?: ClaimDetailCardItem;
  actionable?: ClaimActionable;

  private constructor(title: string, subtitle: string, items: Array<ClaimDetailCardItem>) {
    this.title = title;
    this.subtitle = subtitle;
    this.items = items;
  }

  static create(title: string, subtitle: string, ...params: Array<ClaimDetailCardItem>): ClaimDetailCard {
    return new ClaimDetailCard(title, subtitle, params);
  }

  withActionable(actionable: ClaimActionable) {
    this.actionable = actionable;

    return this;
  }
}

export class ClaimActionable {
  label: string;
  action: () => void;

  private constructor(label: string, action: () => void) {
    this.label = label;
    this.action = action;
  }

  static create(label: string, action: () => void) {
    return new ClaimActionable(label, action);
  }
}

export class ClaimDetailCardItem {
  title: string;
  text: any;

  private constructor(title: string, text: any) {
    this.title = title;
    this.text = text;
  }

  static create(title: string, text: any): ClaimDetailCardItem {
    return new ClaimDetailCardItem(title, text);
  }
}

export class ClaimItem {
  title: string;
  description: Array<string>;

  private constructor(title: string, description: Array<string>) {
    this.title = title;
    this.description = description;
  }

  static create(title: string, description: Array<string>): ClaimItem {
    return new ClaimItem(title, description);
  }
}
