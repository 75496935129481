import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiCoreComponentsModule, ScModalDialogService } from '@sc/claim-components';

// Components
import { ScVersionsModalComponent } from './sc-versions-modal.component';

@NgModule({
  imports: [
    CommonModule,
    UiCoreComponentsModule
  ],
  exports: [
    ScVersionsModalComponent
  ],
  declarations: [
    ScVersionsModalComponent
  ],
  providers: [
    ScModalDialogService
  ]
})
export class ScVersionsModalModule { }
