import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormArray } from '@angular/forms';

@Injectable()

export class ScFormHelper {

    emptyFields(selector): boolean {

        const _inputs = [].slice.call(document.querySelectorAll(selector + ' [required]'));
        const emptyFields = _inputs.filter((el) => !el.value.length);

        return (emptyFields.length > 0);
    }

    validateAllFormFields(formGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            } else if (control instanceof UntypedFormArray) {
                control['controls'].forEach(formArrayControl => {
                    this.validateAllFormFields(formArrayControl);
                });
            }
        });
    }

    blurActiveElement(): void {
        if (document.activeElement['blur']() && document.activeElement && document.activeElement['blur']) {}
    }

}
