import { Component, HostListener } from '@angular/core';
import { environment } from 'environments/environment';
import { ScModalService, ModalConfig } from '@sc/claim-components';

@Component({
  selector: 'sc-versions-modal',
  templateUrl: './sc-versions-modal.component.html'
})
export class ScVersionsModalComponent {

  public apiBaseUrl: string;
  public dependencies: any;
  public modalVersionsOptions: ModalConfig;
  public version: string;

  constructor(
    private modalService: ScModalService,
  ) {
    this.apiBaseUrl = environment.apiBaseUrl;
    this.modalVersionsOptions = {};
    this.version = environment.version;
    this.dependencies = (environment.dependencies) ?
      Object.keys(environment.dependencies).map((dependency) => {
        return (dependency.includes('@sc')) ? {
          dependency: dependency,
          version: environment.dependencies[dependency]
        } : null;
      }).filter((dependency) => !!dependency) : [];
  }

  @HostListener('window:keydown', ['$event'])
  keyboardInput(event: KeyboardEvent) {
    if (event.altKey && event.ctrlKey && event.key === 'v') {
      this.modalVersionsOptions = {
        title: 'Versiones',
        size: 'medium',
        closeButton: true,
      };
      this.modalService.open('versionsModal');
    }
  }

  closeVersionsModal() {
    this.modalService.close('versionsModal');
  }
}
