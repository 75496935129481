export class ClaimPASFile {
  public documentType: string;
  public origin: string;
  public url: string;
  public api: string;
  public fileUploadedDate: Date;
  public fileName: string;
  public downloading: boolean;
  public downloadErrorMessage: string;
  public hasDownloadError: boolean;

  constructor() {
    this.documentType = null;
    this.origin = null;
    this.url = null;
    this.api = null;
    this.fileUploadedDate = null;
    this.fileName = null;
    this.downloading = false;
    this.downloadErrorMessage = null;
    this.hasDownloadError = false;
  }
}
