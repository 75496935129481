import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScDownloadBaremoModalComponent } from './sc-download-baremo-modal.component';
import { ScModalDialogModule, ScModalDialogService } from '@sc/claim-components';
import { ButtonModule } from '@sc/portal.fe.lib.ui-core-components';

@NgModule({
    imports: [
        CommonModule,
        ScModalDialogModule,
        ButtonModule
    ],
    exports: [
        ScDownloadBaremoModalComponent
    ],
    declarations: [
        ScDownloadBaremoModalComponent
    ],
    providers: [
        ScModalDialogService
    ]
})
export class ScDownloadBaremoModalModule {
}
