import { Injectable } from '@angular/core';

// Helpers
import { ApiClient } from 'app/core/helpers/sc-api.helper';

// Environments
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class TasksService {

    constructor(
        private api: ApiClient
    ) { }

    getCurrentTask(activityId) {
        return this.api.get(environment.apiClaim + '/api/activities/' + activityId + '/tasks/current');
    }

    updateTask(activityId, data) {
        return this.api.post(environment.apiClaim + '/api/activities/' + activityId + '/tasks/current', data);
    }

    dispatchAction(activityId, actionId, data, taskId) {
        if (data === undefined) {
            data = {}; // this endpoint doesn't handle undefined/null very well.
        }

        if (taskId) {
            return this.api.post(environment.apiClaim + '/api/activities/' + activityId + '/tasks/' + taskId + '/actions/' +
             actionId, data);
        } else {
            return this.api.post(environment.apiClaim + '/api/activities/' + activityId + '/actions/' + actionId, data);
        }
    }

    sendComment(activityId, data) {
        const httpOptions = { responseType: 'text' };
        return this.api.post(environment.apiClaim + '/api/activities/' + activityId + '/message', data, httpOptions);
    }

    downloadFile(activityId, taskId, fileName): Observable<Blob> {
        const url = environment.apiClaim + '/api/activities/' + activityId + '/tasks/' + taskId + '/files/' + fileName;
        return this.api.get(url, null, { responseType: 'blob' });
    }
}
