import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ScArrayHelper } from './sc-array.helper';

@Injectable()
export class ScUrlHelper {
    public user: any;

    constructor(private router: Router,
        private array: ScArrayHelper
    ) {
    }

    urlByIndexJSON(JSONSource, levels, key = 'items') {
        const ls = levels && levels.length;
        switch (ls) {
            case 1:
                return JSONSource && JSONSource[levels[0] * 1];
            case 2:
                return JSONSource && JSONSource[levels[0] * 1][key][levels[1] * 1];
            case 3:
                return JSONSource && JSONSource[levels[0] * 1] && JSONSource[levels[0] * 1][key] && JSONSource[levels[0] * 1][key][0];
        }
    }

    addUrlParam(url = '', params = {}, redirectTo?): string {
        const ps = Object.keys(params);
        let paramsQuery = '';
        let count = 0;
        ps.forEach(p => {
            if (count > 0) {
                paramsQuery += '&';
            }
            paramsQuery += p + '=' + params[p];
            count++;
        });
        const redirectToUrl = redirectTo ? '&ReturnUrl=' + redirectTo : '';
        const urlWithParams = url.includes('?') ? url.replace('?', '?' + paramsQuery + '&') : url + '?' + paramsQuery;

        return urlWithParams + redirectToUrl;
    }

    setJSONIndex(key = '', i0 = 0, i1 = -1, i2 = -1) {
        return key + '.' + i0 + (i1 >= 0 ? '.' + i1 : '') + (i2 >= 0 ? '.' + i2 : '');
    }

    getFromJSON(json, index) {
        const levels = index && index.split('.');
        const keys = json && Object.keys(json);
        const result = this.array.cloneDeep(json[keys[levels[0]]]);
        levels.shift();
        return this.urlByIndexJSON(result, levels);
    }

}
