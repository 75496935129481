import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scNumber'
})
export class ScNumberPipe implements PipeTransform {

    transform(val: number): string {
        return this.setNumberFormat(val);
    }

    setNumberFormat(value) {
        const text = this.parse(value && typeof value === 'number' && value.toFixed(0) || value || 0);
        return (parseInt(text || '0', 10) || 0 ).toString();
    }

    parse(value) {
        const text = value && value.toString();
        return text && text.length && text !== '' ? text.toString().replace(/\D/g, '') : '0';
    }

    parseInt(value) {
        return parseInt(this.parse(value), 10);
    }

}
