import { Component, OnInit, OnChanges, Input } from '@angular/core';

import { ScModalDialogConfig } from '@sc/claim-components';

import { AuthenticationService } from 'app/core/services';

@Component({
  selector: 'sc-service-agreement-modal',
  templateUrl: './sc-service-agreement-modal.component.html',
  styleUrls: ['./sc-service-agreement-modal.component.scss']
})
export class ScServiceAgreementModalComponent implements OnInit, OnChanges {

  public hasAceptServiceAgreement: boolean;
  public scServiceAgreementModalConfig: ScModalDialogConfig;

  public userInfo: any;

  @Input() loading: boolean;

  constructor(
    private authenticationService: AuthenticationService
  ) {
    this.hasAceptServiceAgreement = false;
    this.loading = false;
    this.scServiceAgreementModalConfig = {
      size: 'large',
      hasBackdrop: true,
      disableClose: false,
      title: 'Acuerdo de servicio',
      titleAlign: 'center',
      titleColor: 'primary-text-dark',
      disableScroll: false,
      showDefaultFooter: true,
      showDefaultHeader: true,
      defaultFooterButtons: {
        close: {
          hidden: true
        },
        confirm: {
          title: 'Aceptar términos y condiciones',
          disabled: false,
          loading: false,
          size: 'medium-fluid',
          type: 'primary'
        }
      },
      defaultFooterButtonsAlign: 'center'
    };
  }

  ngOnInit() {
    this.getUserInfo();
    if (this.authenticationService.isAuthenticated()) {
      this.hasAceptServiceAgreement = true;
      this.scServiceAgreementModalConfig.defaultFooterButtonsAlign = 'right';
      this.scServiceAgreementModalConfig.defaultFooterButtons = {
        close: {
          title: 'Cerrar',
          disabled: false,
          loading: false,
          type: 'secondary',
          size: 'small',
          hidden: false
        },
        confirm: {
          hidden: true
        }
      };
    }
  }

  ngOnChanges() {
    this.scServiceAgreementModalConfig.defaultFooterButtons.confirm.loading = this.loading;
    this.scServiceAgreementModalConfig.disableClose = this.loading;
  }

  getUserInfo() {
    this.userInfo = this.authenticationService.getLocalStorageUserInfo();
  }

}
