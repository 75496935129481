import { ManagementTracking } from 'app/shared/models';
import { ManagementTrackingCard } from 'app/shared/models/management-tracking-card.interface';
import { ManagementTrackingCardStrategy } from './management-tracking-card.strategy';
import { ClaimDetailCardItem } from 'app/shared/models/claimDetail';
import { Injectable } from '@angular/core';

@Injectable()
export class InternalAdministrationStrategy extends ManagementTrackingCardStrategy {
  getCard(managementTracking: ManagementTracking): ManagementTrackingCard {
    return {
      subtitle: 'Administración interna del caso',
      hasActionable: true,
      subItems: [ClaimDetailCardItem.create('', 'Los datos de contacto se visualizan en la sección coberturas.')],
      lastItem: this.getLastItem(managementTracking)
    };
  }
}
