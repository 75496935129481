import { environment } from 'environments/environment';
const appFolder = `common/`;
const imagesFolder = `images/`;
const insuranceFolder = `seguros/`;
const grupoSCFolder = `grupo-san-cristobal/`;

export const insuranceLogo = `${environment.cdn.url}${appFolder}${imagesFolder}${insuranceFolder}logo-seguros-366%C3%9774.svg`;
export const insuranceNegativeLogo = `${environment.cdn.url}${appFolder}${imagesFolder}${insuranceFolder}logo-seguros-negativo-386%C3%9778.svg`;
export const insuranceLogoPrintable = '/assets/images/logo-sc-printable.svg';
export const insuranceNavNegativeLogo = `${environment.cdn.url}${appFolder}${imagesFolder}${grupoSCFolder}logo-iso-sancristobal-negativo.svg`;
