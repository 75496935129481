export class UserInfo {
  public id: string;
  public userRole: string;
  public userName: string;

  constructor(id = '', userRole = '', userName = '') {
    this.id = id;
    this.userRole = userRole;
    this.userName = userName;
  }
}
