import { Person } from './person';

export class Administrator extends Person {

  public internalNumber: string;

  constructor() {
    super();
    this.internalNumber = '';
  }
}
