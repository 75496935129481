<div class="sc-dropdown" 
  [class.sc-dropdown--opened]="_opened"
  [class.sc-dropdown--bottom]="placement === 'bottom' && !dropdownListTemplate"
  [class.sc-dropdown--bottom-right]="placement === 'bottom-right' && !dropdownListTemplate"
  [class.sc-dropdown--bottom-left]="placement === 'bottom-left' && !dropdownListTemplate"
  [class.sc-dropdown--top]="placement === 'top' && !dropdownListTemplate"
  [class.sc-dropdown--top-right]="placement === 'top-right' && !dropdownListTemplate"
  [class.sc-dropdown--top-left]="placement === 'top-left' && !dropdownListTemplate"
  #dropdown>

    <ng-template #defaultButtonItemTemplate>
      <ng-container *ngIf="config && config.dropdownBtn">
          <sc-button 
          [id]="config.dropdownBtn.id" 
          [type]="config.dropdownBtn.type"
          [icon]="config.dropdownBtn.icon" 
          [iconOnly]="config.dropdownBtn.iconOnly" 
          [suffixIcon]="config.dropdownBtn.suffixIcon" 
          [size]="config.dropdownBtn.size" 
          [disabled]="config.dropdownBtn.disabled" 
          [loading]="config.dropdownBtn.loading" 
          (onClick)="handleClick($event)">
          {{config.label}}
        </sc-button>
      </ng-container>
    </ng-template>

    <ng-template #defaultDropdownListTemplate>
      <ng-container *ngIf="config && config.dropdownBtn && config.menu">
        <div class="sc-dropdown__menu-container">
          <ul class="sc-dropdown__menu" *ngIf="_opened" (scClickOutside)="handleClickOutside($event)" [clickOutsideEvents]="'mousedown,touchstart'" [exclude]="'#'+config.dropdownBtn.id" [excludeBeforeClick]="true" [@dropdownAnimation]="_opened ? placement : 'void'" #dropdownMenu>
            <li class="sc-dropdown__menu-item" *ngFor="let item of config.menu; let i = index;">
              <a [id]="'sc-dropdown-item-' + item.id" class="sc-dropdown__menu-item-link" (click)="handleMenuItemClick(item, i)">
                <sc-icon class="sc-dropdown__menu-item-icon" [icon]="item.icon"></sc-icon>{{item.label}}
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </ng-template>
  
    <ng-container 
      [ngTemplateOutlet]="buttonItemTemplate || defaultButtonItemTemplate" 
      [ngTemplateOutletContext]="{ opened: _opened }">
    </ng-container>
  
    <ng-container
      [ngTemplateOutlet]="dropdownListTemplate || defaultDropdownListTemplate" 
      [ngTemplateOutletContext]="{ items: config.menu, opened: _opened }">
    </ng-container>

</div>