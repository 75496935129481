import { stagger, trigger, transition, animate, query, style } from '@angular/animations';

export const timelineListAnimation = trigger('timelineListAnimation', [
  transition(':enter', [
    query('tr', [
      style({ opacity: 0, overflow: 'hidden' }),
      stagger(50, [
        animate('800ms ease-in-out', style({ opacity: 1 }))
      ])
    ])
  ]),
  transition(':leave', [
    query('tr', [
      style({ opacity: 1, overflow: 'hidden' }),
      stagger(10, [
        animate('50ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ])
]);
