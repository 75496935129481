const apiDev = 'https://api-dev.sancristobalonline.com.ar/';

const apiBaseUrl = apiDev;

export const environment = {
    version: require('../../package.json').version,
    dependencies: require('../../package.json').dependencies,
    type: {
        id: 'int',
        name: 'Development'
    },
    apiBaseUrl: apiBaseUrl,
    production: false,
    legacyPortal: 'https://diw2k3apl02/PortalSCNet/Productores/homeproductores.aspx',
    legacyPortalBase: 'https://diw2k16apl01.gruposancristobal.org.ar',
    OORRPortalBase: 'https://dev-siniestrosrsvs.sancristobalonline.com.ar',
    thirdPartyPortalBase: 'https://dev.sancristobalonline.com.ar',
    apiAuthUrl: apiBaseUrl + 'authapi',
    apiClaim: apiBaseUrl + 'siniestros-claim',
    apiAdmin: apiBaseUrl + 'siniestros-claimadmin',
    apiCommercialAutoUrl: apiBaseUrl + 'portalpas-commercialauto',
    apiUtilityUrl: apiBaseUrl + 'utility',
    apiNotifications: apiBaseUrl + 'siniestros-notification',
    ca7claim: apiBaseUrl + 'siniestros-ca7claim/api',
    account: 'claimimages',
    container: 'claimimages',
    appInsightsKey: '43ab8f1a-30b1-4f1a-a091-690fbc3a0e4f',
    hotJarSiteId: 880032,
    apiClaimV2: apiBaseUrl + 'claim/api',
    lender: apiBaseUrl + `lender/api`,
    google: {
        taskManager: {
            token: 'GTM-5GX3GP9'
        }
    },
    server: {
        web: {
            home: 'https://portalpas-dev.sancristobalonline.com.ar'
        }
    },
    cdn: {
        url: 'https://content-dev.gruposancristobal.com/',
    }
};
