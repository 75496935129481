<div class="sc-header">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="sc-header__content">
          <div class="sc-header__left">
            <img [src]="insuranceLogo" class="sc-header__left-logo" alt="header logo">
          </div>
          <div class="sc-header__right">
            <button class="sc-header__right-logout-btn" id="sc-button-logout" (click)="logout()" *ngIf="isLogoutButtonAvailable">Cerrar sesión</button>
            <div class="sc-header__right-notifications" *ngIf="isNotificationsAvailable">
              <sc-notifications [timeRefresh]="true"></sc-notifications>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
