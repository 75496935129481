import { Injectable } from '@angular/core';

import { ApiClient } from 'app/core/helpers/sc-api.helper';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class StatisticsService {

  constructor(
    private api: ApiClient
  ) {
  }

  getStatistics(data) {
    return this.api.post(environment.apiClaim + '/api/statistics/WithParams', data);
  }

  getXlsStatistics(data): Observable<Blob> {
    return this.api.post(environment.apiClaim + '/api/statistics/SearchAndDownloadStatistics', data, { responseType: 'blob' });
  }

  saveStatistics(value) {
    window.localStorage.setItem('statistics', JSON.stringify(value));
  }

  loadStatistics() {
    return JSON.parse(window.localStorage.getItem('statistics')) || [];
  }

  saveStatistic(value) {
    const statistics = this.loadStatistics();
    const statisticIndex = statistics.findIndex((statistic) => statistic.activityId === value.activityId);

    if (statisticIndex !== -1) {
      statistics[statisticIndex] = value;
    } else {
      statistics.push(value);
    }

    this.saveStatistics(statistics);
  }

  loadStatistic(parameters = {}) {
    const statistics = this.loadStatistics();
    return statistics.filter((statistic) => {
      const keys = Object.keys(parameters);
      let matchCount = 0;
      keys.forEach(function (key) {
        if (statistic[key] === parameters[key]) {
          matchCount++;
        }
      });
      return matchCount === keys.length;
    })[0];
  }
}
