import { trigger, style, animate, transition } from '@angular/animations';

export const rotateFade = trigger('rotateFade', [
    transition(':enter', [
        style({
            opacity: 0,
            transform: 'rotate(-90deg)',
        }),
        animate(500, style({
            opacity: 1,
            transform: 'rotate(0)',
        }))
    ]),
    transition(':leave', [
        animate(1000, style({
            opacity: 0,
            transform: 'rotate(90deg)',
        }))
    ])
]);
