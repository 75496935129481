<sc-modal-dialog #modal class='sc-download-baremo-modal' id='downloadBaremoModal'
                 [scModalDialogConfig]='scDownloadBaremoModalConfig'>
    <div class='sc-download-baremo-modal__body'>
        <p class='sc-download-baremo-modal__text'>Descargá uno o ambos baremos para poder</p>
        <p class='sc-download-baremo-modal__text'>consultarlos y tenerlos a mano siempre que necesites.</p>

        <div class='sc-download-baremo-modal__content-car'>
            <sc-button class='sc-download-baremo-modal__button-car' type='link'
                       prefixIconClass='fas fa-regular fa-arrow-down-to-line fa-lg'
                       (onClick)='downloadFile(downloadCar)'>Descargar baremo vehículo mediano
            </sc-button>
        </div>
        <div>
            <sc-button class='sc-download-baremo-modal__button-van' type='link'
                       prefixIconClass='fas fa-regular fa-arrow-down-to-line fa-lg'
                       (onClick)='downloadFile(downloadPickUp)'>Descargar baremo vehículo camioneta
            </sc-button>
        </div>

        <footer class='sc-download-baremo-modal__footer'>
            <div class='sc-download-baremo-modal__footer-content'>
                <sc-button class='sc-download-baremo-modal__footer-button' type='primary' size='large'
                           (onClick)='downloadFile(downloadAll)'>Descargar todos
                </sc-button>
                <sc-button class='sc-download-baremo-modal__footer-button' type='secondary' size='large'
                           (onClick)='this.modal.close()'>Cancelar
                </sc-button>
            </div>
        </footer>
    </div>
</sc-modal-dialog>
