import { Injectable } from '@angular/core';

@Injectable()

export class ScArrayHelper {

    cloneDeep(mainArray) {
        return JSON.parse(JSON.stringify(mainArray));
    }
}
