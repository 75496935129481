import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

// Helpers
import { ApiClient } from './helpers/sc-api.helper';
import { ScArrayHelper } from './helpers/sc-array.helper';
import { ScDomHelper } from './helpers/sc-dom.helper';
import { ScDateHelper } from './helpers/sc-date.helper';

// Services
import { AnalyticsService } from './services/analytics.service';
import { AuthenticationService } from './services/authentication.service';
import { TermMetricsService } from './services/term-metrics.service';

// Guards
import { AuthGuard } from './guards/auth.guard';
import { PermissionsGuard } from './guards/permissions.guard';

@NgModule({
    providers: [
        // Global Helpers
        ApiClient,
        CurrencyPipe,
        ScArrayHelper,
        ScDateHelper,
        ScDomHelper,

        // Global Services
        AnalyticsService,
        AuthenticationService,
        TermMetricsService,

        // Global Guards
        AuthGuard,
        PermissionsGuard
    ],
})
export class CoreModule {

    constructor(@Optional() @SkipSelf() core: CoreModule) {
        if (core) {
            throw new Error('CoreModule imported more than once. It should only be imported by AppModule.');
        }
    }
}
