export class Person {

  public documentNumber: string;
  public documentType: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public phoneNumber: string;

  constructor() {
    this.documentNumber = '';
    this.documentType = '';
    this.email = '';
    this.firstName = '';
    this.lastName = '';
    this.phoneNumber = '';
  }
}
