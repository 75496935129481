import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { ScModalDialogService, ScModalDialogConfig } from '@sc/claim-components';

import { AuthenticationService, SiniestrosService } from 'app/core/services';

@Component({
  selector: 'sc-news-modal',
  templateUrl: './sc-news-modal.component.html',
  styleUrls: ['./sc-news-modal.component.scss']
})
export class ScNewsModalComponent implements OnInit {

  public scNewsModalConfig: ScModalDialogConfig;

  public platformNews: any;

  public url: string;
  public safeResourceUrl: SafeResourceUrl;

  public loading: boolean;

  constructor(
    public scModalDialogService: ScModalDialogService,
    public authenticationService: AuthenticationService,
    public siniestrosService: SiniestrosService,
    public sanitized: DomSanitizer
  ) {
    this.scNewsModalConfig = {
      size: 'large',
      title: 'Novedades de la plataforma',
      titleAlign: 'center',
      titleColor: 'primary-text-dark',
      disableScroll: true,
      showDefaultHeader: true,
      showDefaultFooter: true,
      defaultFooterButtons: {
        close: {
          title: 'Entendido!',
          size: 'small-fluid',
          type: 'primary'
        },
        confirm: {
          hidden: true
        }
      },
      defaultFooterButtonsAlign: 'right',
      height: '650'
    };
    this.loading = true;
  }

  ngOnInit() {
    if (this.authenticationService.isPlatformUser()) {
      this.getNews();
    }
  }

  getNews() {
    this.authenticationService.getNews().subscribe(
      (res) => {
        this.platformNews = res;
        this.safeResourceUrl = this.sanitized.bypassSecurityTrustResourceUrl(this.platformNews.path);
        if (!this.platformNews.readed) {
          this.scModalDialogService.open('newsModal');
          this.authenticationService.setNews().subscribe();
        } else {
          this.scNewsModalConfig.showDefaultFooter = false;
        }
      }
    );
  }

  _onIframeLoad(obj) {
    setTimeout(() => { this.loading = false; }, 0);
  }

  _onOpenModal(e) {
    setTimeout(() => { this.loading = true; }, 0);
  }
}
