import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { ScDropdownComponent } from './sc-dropdown.component';

// Directives
import { ScDropdownBtnItemDirective } from './sc-button-item.directive';
import { ScDropdownListDirective } from './sc-dropdown-list.directive';

// Modules
import { UiCoreComponentsModule } from '@sc/claim-components';
import { ScDirectivesModule } from 'app/shared/directives/sc-directives.module';

@NgModule({
  imports: [
    CommonModule,
    UiCoreComponentsModule,
    ScDirectivesModule
  ],
  declarations: [
    ScDropdownComponent,
    ScDropdownBtnItemDirective,
    ScDropdownListDirective
  ],
  exports: [
    ScDropdownComponent,
    ScDropdownBtnItemDirective,
    ScDropdownListDirective
  ]
})
export class ScDropdownModule { }
