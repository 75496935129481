<div class="wrapper">
    <sc-header-info *ngIf="showHeaderInfo" [userInfo]="userInfo"></sc-header-info>
    <header [class.with-header-info]="showHeaderInfo" *ngIf="showHeader">
        <sc-header></sc-header>
    </header>
    <main [ngClass]="{'main-admin': userInfo.userRole === 'Administrador'}">
        <router-outlet (activate)="updateHeaderVisibility()"></router-outlet>
    </main>
    <footer *ngIf="showFooter">
        <sc-footer></sc-footer>
    </footer>
    <sc-versions-modal></sc-versions-modal>
</div>