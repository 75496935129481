import { trigger, state, style, animate, transition } from '@angular/animations';

export const slideFade = trigger('slideFade', [
    state('void', style({
        height: 0,
        overflow: 'hidden'
    })),
    state('out', style({
        height: 0,
        overflow: 'hidden',
        zIndex: -1,
        opacity: 0
    })),
    state('in', style({
        zIndex: 100,
        // overflow: 'hidden'
    })),
    transition('* <=> *', animate('300ms')),
]);

