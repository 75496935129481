<table class="sc-table" [ngClass]="{'sc-table--selectable': rowSelectable }">
  <thead class="sc-table__head hidden-sm">
    <tr class="sc-table__head-row">
      <ng-container *ngTemplateOutlet="rowHeaderTemplate"></ng-container>
    </tr>
  </thead>
  <tbody class="sc-table__body" *ngIf="!loading && items && items.length" [@tableListAnimation]="items.length">
    <tr [class]="(id) ? 'sc-table__body-row ' + id + '__row' : 'sc-table__body-row'" *ngFor="let item of items; let index = index;" (click)="selectRow(item)">
      <ng-container *ngTemplateOutlet="rowItemTemplate, context: { item: item, index: index }"></ng-container>
    </tr>
  </tbody>
  <tbody class="sc-table__placeholder-body" *ngIf="loading" [@timelineListAnimation]="items.length">
    <tr class="sc-table__placeholder-body-row timeline animated-background" *ngFor="let number of placeholderItems; let index = index;">
      <ng-container *ngTemplateOutlet="rowItemPlaceholderTemplate, context: { item: item, index: index }"></ng-container>
    </tr>
  </tbody>
</table>