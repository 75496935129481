import { Person } from './person';
import { Schedule } from '../components/sc-schedule/sc-schedule.model';

export class Workshop extends Person {

  public address: string;
  public businessName: string;
  public branchOffice?: number;
  public claimUserName?: string;
  public contact?: WorkshopContact;
  public schedules?: Array<Schedule>;
  public cuit?: string;
  public location?: WorkshopLocation;
  public region?: number;
  public workshopType?: number;
  public isGeolocalizationValidated: boolean;

  constructor() {
    super();
  }
}

export class WorkshopContact {
  public areaCode: string;
  public email: string;
  public internalPhoneNumber: string;
  public phoneNumber: string;
}

export class WorkshopLocation {
  public province: string;
  public locality: string;
  public zipCode: string;
  public street: string;
  public addressNumber: string;
  public latitude: number;
  public longitude: number;
}
