import { Injectable } from '@angular/core';
import { ManagementTracking } from 'app/shared/models';
import { ClaimDetailCardItem } from 'app/shared/models/claimDetail';
import { ManagementTrackingCard } from 'app/shared/models/management-tracking-card.interface';
import { ManagementTrackingCardStrategy } from './management-tracking-card.strategy';

@Injectable()
export class InitialAnalysisStrategy extends ManagementTrackingCardStrategy {
  getCard(managementTracking: ManagementTracking): ManagementTrackingCard {
    return {
      subtitle: 'Análisis inicial del caso',
      subItems: [
        ClaimDetailCardItem.create('Administrador/a', managementTracking.administrator?.fullName),
        ClaimDetailCardItem.create('Email', managementTracking.administrator?.email)
      ]
    };
  }
}
