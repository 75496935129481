<div class="row sc-empty-table-message">
  <div class="col-md-6 sc-empty-table-message__announcement">
    <p class="sc-empty-table-message__title" [innerHTML]="title"></p>
    <p class="sc-empty-table-message__message mt-1" [innerHTML]="message"></p>
  </div>
  <div class="col-md-6">
    <div class="sc-empty-table-message__image-container">
      <img src="/assets/images/papers.svg" class="sc-empty-table-message__image img-fluid w-100" alt="icon-papers">
    </div>
  </div>
</div>
