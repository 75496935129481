import { Component, Input } from '@angular/core';

// Environments
import { environment } from 'environments/environment';

@Component({
  selector: 'sc-header-info',
  templateUrl: './header-info.component.html',
  styleUrls: ['./header-info.component.scss']
})
export class ScHeaderInfoComponent {

  @Input() userInfo: any;
  public _environment;

  constructor() {
    this.userInfo = {};
    this._environment = environment;
  }

}
