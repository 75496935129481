import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class TermMetricsService {
    initHJ() {
        // Hot jar
        (function (self, h, o, t, j, a, r) {
            h['hj'] = h['hj'] || (function () {
                (h['hj'].q = h['hj'].q || []).push(arguments);
            });
            h['_hjSettings'] = { hjid: environment.hotJarSiteId, hjsv: 6 };

            if (!environment.production) {
                h['_hjSettings'].hjdebug = false;
            }
            a = o.getElementsByTagName('head')[0];
            r = o.createElement('script');
            r.async = 1;
            r.src = t + h['_hjSettings'].hjid + j + h['_hjSettings'].hjsv;
            a.appendChild(r);
        })(this, window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    }

    event(e = 'mypoll') {
        window['hj']('trigger', e);
    }

    clear() {
        if (window['hj'] && window['hj']['widget'] && window['hj']['widget'].clearWidget) {
            window['hj']['widget'].clearWidget();
        }
    }
}
