import { Injectable } from '@angular/core';
import { BaremoType } from '../../shared/models/baremoType.enum';

@Injectable()
export class FileDownloadService {

    private dictionaryBaremoFilePaths: { [key in BaremoType]: string } = {
        [BaremoType.car]: './assets/priceListFiles/Baremo-vehiculo-mediano.pdf',
        [BaremoType.pickup]: './assets/priceListFiles/Baremo-vehiculo-camioneta.pdf',
        [BaremoType.all]: './assets/priceListFiles/Baremos.zip'
    };

    private dictionaryBaremoFileNames: { [key in BaremoType]: string } = {
        [BaremoType.car]: 'Baremo-vehiculo-mediano.pdf',
        [BaremoType.pickup]: 'Baremo-vehiculo-camioneta.pdf',
        [BaremoType.all]: 'Baremos.zip'
    };

    constructor() {
    }

    public downloadBeremoFilesWithAuth(type: BaremoType) {
        const link = document.createElement('a');
        link.href = (this.dictionaryBaremoFilePaths)[type];
        link.download = (this.dictionaryBaremoFileNames)[type];
        link.click();
    }
}
