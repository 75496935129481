import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';

import { AuthGuard } from './core/guards/auth.guard';
import { PermissionsGuard } from './core/guards/permissions.guard';

const appRoutes: Routes = [
    {
        path: 'forbidden',
        canActivate: [PermissionsGuard],
        loadChildren: () => import('./components/forbidden/forbidden.module').then(m => m.ForbiddenModule)
    },
    {
        path: 'error',
        canActivate: [PermissionsGuard],
        loadChildren: () => import('./components/error-page/error-page.module').then(m => m.ErrorPageModule)
    },
    {
        path: 'authentication',
        canActivate: [PermissionsGuard],
        loadChildren: () => import('./components/authentication/authentication.module').then(m => m.AuthenticationModule)
    },
    {
        path: 'siniestros',
        canActivate: [AuthGuard, PermissionsGuard],
        loadChildren: () => import('./components/landing/landing.module').then(m => m.LandingModule)
    },
    {
        path: 'siniestros/tracking',
        canActivate: [AuthGuard, PermissionsGuard],
        loadChildren: () => import('./components/tracking/tracking.module').then(m => m.TrackingModule)
    },
    {
        path: 'siniestros/detalle',
        canActivate: [AuthGuard, PermissionsGuard],
        loadChildren: () => import('./components/detalle-siniestro/detalle-siniestro.module').then(m => m.DetalleSiniestroModule)
    },
    {
        path: 'admin',
        canActivate: [AuthGuard, PermissionsGuard],
        loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule)
    },
    {
        path: '**',
        component: AppComponent,
        canActivate: [AuthGuard, PermissionsGuard]
    }
];

// Cambiar 'useHash' a 'true' si queremos usar hash en la URL
export const Routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, {});
