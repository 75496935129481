export class Dropdown {
    public key: string;
    public value: any;
    public fullName?: string;
}

export class DropdownResponse {
    public continuationToken: string;
    public hasMoreItems: boolean;
    public items: Dropdown[];
}
