import { Injectable } from '@angular/core';

@Injectable()

export class ScDomHelper {

    isSmallViewport() {
        const SMALL_SCREEN_BREAKPOINT = 768;
        return window.innerWidth < SMALL_SCREEN_BREAKPOINT;
    }


    isMobile() {
        const SMALL_SCREEN_BREAKPOINT = 768;
        return window.screen.width < SMALL_SCREEN_BREAKPOINT;
    }

    isTouchDevice() {
        return 'ontouchstart' in window        // works on most browsers
            || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    }


    scrollTo(offSetElement, desktop = 200, mobile = 200) {
        const content = document.body;
        if (content && offSetElement) {
            const height = window.screen.width >= 768 ? desktop : mobile;
            setTimeout(() => {
                window.scrollTo(0, offSetElement['offsetTop'] + height);
            }, 500);
        }
    }

    scrollToSlow(element, to, duration) {
        if (duration <= 0) {
            window.scrollTo(0, to);
            return;
        }
        const difference = to - element.scrollTop;
        const perTick = difference / duration * 10;

        setTimeout(() => {
            window.scrollTo(0, element.scrollTop + perTick);
            if (element.scrollTop === to) {
                return;
            }
            this.scrollToSlow(element, to, duration - 5);
        }, 5);
    }


    getCurrentYPosition() {
        return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    }

    getElementYPosition(element) {
        let y = element.offsetTop;
        let node = element;

        while (node.offsetParent && node.offsetParent !== document.body) {
            node = node.offsetParent;
            y += node.offsetTop;
        }

        return y;
    }

    easingPattern(type, time) {
        const easings = {
            // accelerating from zero velocity
            easeInQuad: () => time * time,
            // decelerating to zero velocity
            easeOutQuad: () => time * (2 - time),
            // acceleration until halfway, then deceleration
            easeInOutQuad: () => time < 0.5 ? 2 * time * time : -1 + (4 - 2 * time) * time,
            // accelerating from zero velocity
            easeInCubic: () => time * time * time,
            // decelerating to zero velocity
            easeOutCubic: () => (--time) * time * time + 1,
            // acceleration until halfway, then deceleration
            easeInOutCubic: () => time < 0.5 ? 4 * time * time * time : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1,
            // accelerating from zero velocity
            easeInQuart: () => time * time * time * time,
            // decelerating to zero velocity
            easeOutQuart: () => 1 - (--time) * time * time * time,
            // acceleration until halfway, then deceleration
            easeInOutQuart: () => time < 0.5 ? 8 * time * time * time * time : 1 - 8 * (--time) * time * time * time,
            // accelerating from zero velocity
            easeInQuint: () => time * time * time * time * time,
            // decelerating to zero velocity
            easeOutQuint: () => 1 + (--time) * time * time * time * time,
            // acceleration until halfway, then deceleration
            easeInOutQuint: () => time < 0.5 ? 16 * time * time * time * time * time : 1 + 16 * (--time) * time * time * time * time,
        };

        return (easings[type]) ? easings[type]() : time; // no easing, no acceleration
    }

    smoothScrollTo(element: Node, offset = 0, speed = 500, ease = 'easeInQuad', scrollEndsCallback?: Function) {
        const startY = this.getCurrentYPosition();
        const stopY = this.getElementYPosition(element) + offset;
        const isScrollDown = stopY > startY;
        const distance = (isScrollDown) ? stopY - startY : startY - stopY;
        let step = 0;
        let leapY = 0;
        let timeLapsed = 0;
        let percentage;

        if (distance < 100) {
            scrollTo(0, stopY);
            if (scrollEndsCallback) {
                scrollEndsCallback();
            }
        } else {
            const loopAnimateScroll = () => {
                timeLapsed += 16;
                percentage = (timeLapsed / speed);
                percentage = (percentage > 1) ? 1 : percentage;
                step = distance * this.easingPattern(ease, percentage);
                leapY = (isScrollDown) ? startY + step : startY - step;
                window.scrollTo(0, leapY);

                if ((isScrollDown && leapY < stopY) || (!isScrollDown && leapY > stopY)) {
                    window.requestAnimationFrame(loopAnimateScroll);
                } else {
                    if (scrollEndsCallback) {
                        scrollEndsCallback();
                    }
                }
            };
            window.requestAnimationFrame(loopAnimateScroll);
        }
    }
}
