<sc-modal-dialog class="sc-contact-info-modal" id="contactInfoModal" [scModalDialogConfig]="contactInfoModalOptions" (onClose)="closeModal()">
    <header>
        <sc-title type="h2" color="primary-brand" content="Datos de contacto"></sc-title>
    </header>
    <section class="sc-contact-info-modal__main">
        <div *ngIf="modalScreen==='mainScreen'">
            <div class="row">
                <div class="col-md-4" *ngFor="let contact of contactsTop">

                    <div class="sc-contact-info-modal__info-box">
                        <div class="sc-contact-info-modal__info-box-title">{{ contact.label }}</div>
                        <div class="sc-contact-info-modal__info-box-body">
                            <p>
                                <span *ngIf="contact.businessName">{{ contact.businessName }}</span>
                                <span *ngIf="!contact.businessName">{{ (contact.firstName || '') + ' ' +
                                    (contact.lastName || '') }}</span>
                            </p>
                            <p class="sc-contact-info-modal__info-box-body-email" *ngIf="contact.email">
                                <a [href]="'mailto:' + contact.email">{{ contact.email }}</a>
                            </p>
                            <p *ngIf="contact.phoneNumber">{{ (contact.internalNumber) ? contact.phoneNumber + ' int '
                                + contact.internalNumber : contact.phoneNumber }}</p>
                        </div>
                    </div>

                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6" [ngClass]="{ 'col-md-12': !siniestro.contact }">

                    <div class="sc-contact-info-modal__info-box">
                        <div class="sc-contact-info-modal__info-box-title">
                            Contacto
                        </div>
                        <div class="sc-contact-info-modal__info-box-body" *ngIf="siniestro && siniestro.contact">
                            <p *ngIf="siniestro.contact.name">{{ siniestro.contact.name }}</p>
                            <p class="sc-contact-info-modal__info-box-body-email" *ngIf="siniestro.contact.email">{{
                                siniestro.contact.email }}</p>
                            <p *ngIf="siniestro.contact.phoneNumber">{{ siniestro.contact.phoneNumber }}</p>
                        </div>
                        <div class="sc-contact-info-modal__info-box-body" *ngIf="siniestro && !siniestro.contact">
                            <sc-button type="secondary" size="medium-fluid" (onClick)="changeScreen('contactEditScreen')">Agregar
                                datos de contacto</sc-button>
                        </div>
                    </div>

                </div>
                <div class="col-md-6" *ngIf="siniestro && siniestro.contact">
                    <div class="sc-contact-info-modal__actions">
                        <sc-button type="secondary" size="small-fluid" (onClick)="changeScreen('contactEditScreen')">Editar
                            datos</sc-button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-4" *ngFor="let contact of contactsBottom">

                    <div class="sc-contact-info-modal__info-box">
                        <div class="sc-contact-info-modal__info-box-title">{{ contact.label }}</div>
                        <div class="sc-contact-info-modal__info-box-body">
                            <p>
                                <span *ngIf="contact.businessName">{{ contact.businessName }}</span>
                                <span *ngIf="!contact.businessName">{{ (contact.firstName || '') + ' ' + (contact.lastName || '') }}</span>
                            </p>
                            <p class="contact.lawFirm">{{ contact.lawFirm }}</p>
                            <p class="sc-contact-info-modal__info-box-body-email" *ngIf="contact.email">
                                <a [href]="'mailto:' + contact.email">{{ contact.email }}</a>
                            </p>
                            <p *ngIf="contact.phoneNumber">{{ (contact.internalNumber) ? contact.phoneNumber + ' int '
                                + contact.internalNumber : contact.phoneNumber }}</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="modalScreen==='contactEditScreen'">
            <div class="sc-contact-info-modal__main__form-error mb-1" *ngIf="errorMessage">
                <sc-icon icon="icon-alert-elipse-copy"></sc-icon>
                <span class="sc-contact-info-modal__main__form-error-message">{{ errorMessage }}</span>
            </div>
            <form [formGroup]="contactEditForm" novalidate>
                <div class="form-group mb-1">
                    <sc-input-2 label="Nombre" size="large" formControlName="name" [errorMessages]="nameCustomMessage"></sc-input-2>
                </div>
                <div class="form-group mb-1">
                    <sc-input-2 label="Teléfono" type="text" size="large" formControlName="phoneNumber"
                        [errorMessages]="phoneNumberCustomMessage"></sc-input-2>
                </div>
                <div class="form-group mb-2">
                    <sc-input-2 label="Email" type="email" size="large" formControlName="email"
                        [errorMessages]="emailCustomMessage"></sc-input-2>
                </div>
            </form>
        </div>
    </section>
    <footer class="sc-contact-info-modal__footer" *ngIf="modalScreen==='contactEditScreen'">
        <div class="row">
            <div class="col-sm-6">
                <sc-button type="secondary" size="large" (onClick)="cancelContactEdition()" [disabled]="modalLoading">Cancelar</sc-button>
            </div>
            <div class="col-sm-6">
                <sc-button type="primary" size="large" (onClick)="updateContactInfo()" [loading]="modalLoading" [disabled]="contactEditForm.invalid">Guardar</sc-button>
            </div>
        </div>
    </footer>
</sc-modal-dialog>
