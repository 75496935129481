export enum LayoutType {
    context = 'context',
    custom = 'custom',
    primary = 'primary',
    secondary = 'secondary'
}

export class Action {
    public canExecute: () => boolean;
    public disabled: boolean;
    public icon: string;
    public id: string;
    public label: string;
    public layout: LayoutType;
    public loading: boolean;

    constructor(id, label, layout = LayoutType.primary, icon = '', canExecute: () => boolean, loading = false, disabled = false) {
        this.canExecute = canExecute;
        this.disabled = disabled;
        this.icon = icon;
        this.id = id;
        this.label = label;
        this.layout = layout;
        this.loading = loading;
    }
}
