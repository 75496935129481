import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scAlphanumeric'
})
export class ScAlphanumericPipe implements PipeTransform {

    transform(val: number): string {
        const str = (val) ? val.toString() : '';
        const regex = /[^\w\s]/gi;
        return str.replace(regex, '').replace('_', '').trim();
    }

}
