import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scCreditCardNumber'
})
export class ScCreditCardNumberPipe implements PipeTransform {

    transform(value: string): string {
        return this.addCardDigitsGroupSeparator(value, ' ');
    }

    parse(value) {
        const text = value && value.toString();
        return text && text.length && text !== '' ? text.toString().replace(/\D/g, '') : '';
    }

    addCardDigitsGroupSeparator(value, thousandsSeparatorSymbol) {
        return this.reverseString(this.reverseString(value).replace(/\B(?=(\d{4})+(?!\d))/g, thousandsSeparatorSymbol));
    }

    reverseString(str) {
        return str.split('').reverse().join('');
    }
}
