import { Dictionary } from './dictionary';

export class UnitType {
    dataType: 'string' | 'number' | 'currency';
    title: string;
    titlePl: string;
    listValues: Array<any>;
    emptyValue: string;
    priceTitle: string;

    constructor() {
        this.dataType = 'string';
        this.title = '';
        this.titlePl = '';
        this.listValues = null;
        this.emptyValue = '';
        this.priceTitle = '';
    }
}

export class PriceType {
    title: string;
    name: string;
    value: number;

    constructor() {
        this.title = '';
        this.name = '';
        this.value = 0;
    }
}

export class ColumnType {
    title: string;
    order: number;
    placeholder: string;
    priceTypes: Array<PriceType>;
    unitType: UnitType;
    width: any;

    constructor() {
        this.title = '';
        this.order = 0;
        this.placeholder = '';
        this.priceTypes = null;
        this.unitType = null;
        this.width = '';
    }
}

export class Row implements Dictionary<any> {}

export class TableType {
    columns: Dictionary<ColumnType>;

    constructor() {
        this.columns = {};
    }
}

export class PriceQuote {
    tableType: TableType;
    prices: Dictionary<PriceType>;
    rows: Array<Row>;
    summary: Dictionary<number>;
    total: number;
    deductible: number;
    covered: number;
    observations: string;

    constructor() {
        this.tableType = new TableType();
        this.prices = {};
        this.rows = [];
        this.summary = {};
        this.total = 0;
        this.deductible = 0;
        this.covered = 0;
        this.observations = '';
    }
}
