import { Administrator } from './administrator';
import { Insured } from './insured';
import { Person } from './person';
import { ClaimAdjuster } from './claimAdjuster';

class ProducerPerson extends Person {

  public address: string;
  public businessName: string;

  constructor() {
    super();
    this.address = '';
    this.businessName = '';
  }
}

class Averages {
  public average: number;
  public median: number;
  public mode: number;

  constructor() {
    this.average = null;
    this.median = null;
    this.mode = null;
  }
}

export class StatisticResultDetail {
  public administrator: Administrator;
  public allSparePartsReceivedDate: Date;
  public appointmentDate: Date;
  public appointmentDelayLifecycle: number;
  public appointmentLifecycle: number;
  public appointmentSendedDate: Date;
  public assignDate: Date;
  public branchOffice: string;
  public branchOfficeId: number;
  public claimAdjuster: ClaimAdjuster;
  public claimAdjusterLifecycle: number;
  public claimDate: Date;
  public claimEventDate: Date;
  public claimNumber: string;
  public countExtension: number;
  public currenStep: string;
  public endRepairDate: Date;
  public extensionDate: Date;
  public extensionLifecycle: number;
  public hasSpareParts: boolean;
  public insured: Insured;
  public isCloseWithoutUserAgreement: boolean;
  public IsDone: boolean;
  public IsEndRepairWithoutUserAgreement: boolean;
  public isUserAgreementSended: boolean;
  public lifecycle: number;
  public managementLifecycle: number;
  public priceQuoteIsModified: boolean;
  public producerPerson: ProducerPerson;
  public repairDate: Date;
  public repairLifecycle: number;
  public sparePartsReceivedLifecycle: number;
  public userAgreementDate: Date;
  public verificationSendDate: Date;
  public workOrderReceivedDate: Date;
  public workOrderRejected: boolean;
  public workOrderRejectedDetails: string;
  public workshopUser: string;
}

export class StatisticResult {

  public activityTrackingExtends: Array<StatisticResultDetail>;

  public count: number;
  public countClose: number;
  public countOpen: number;
  public countCloseWithoutUserAgreement: number;
  public countEndRepairWithoutUserAgreement: number;
  public countClaimWithExtension: number;
  public averageLifecycle: Averages;
  public averageManagementLifecycle: Averages;
  public averageClaimAdjusterLifecycle: Averages;
  public averageRepairWithSparePartsLifecycle: Averages;
  public averageRepairWithoutSparePartsLifecycle: Averages;
  public averageExtensionLifecycle: Averages;
  public averageAppointmentLifecycle: Averages;
  public averageSparePartsReceivedLifecycle: Averages;
  public averageAppointmentDelayLifecycle: Averages;

  constructor() {
    this.count = null;
    this.countClose = null;
    this.countOpen = null;
    this.countCloseWithoutUserAgreement = null;
    this.countEndRepairWithoutUserAgreement = null;
    this.countClaimWithExtension = null;
    this.averageLifecycle = new Averages();
    this.averageManagementLifecycle = new Averages();
    this.averageClaimAdjusterLifecycle = new Averages();
    this.averageRepairWithSparePartsLifecycle = new Averages();
    this.averageRepairWithoutSparePartsLifecycle = new Averages();
    this.averageExtensionLifecycle = new Averages();
    this.averageAppointmentLifecycle = new Averages();
    this.averageSparePartsReceivedLifecycle = new Averages();
    this.averageAppointmentDelayLifecycle = new Averages();
  }
}
