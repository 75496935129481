import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from 'app/core/services';
import { insuranceLogo } from 'app/shared/constants/cdn-files';

@Component({
  selector: 'sc-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [AuthenticationService]
})
export class HeaderComponent implements OnInit {

  public isLogoutButtonAvailable: boolean;
  public isNotificationsAvailable: boolean;
  insuranceLogo = insuranceLogo;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.isLogoutButtonAvailable = this.hasPermission('LOGOUT_BUTTON__VIEW');
    this.isNotificationsAvailable = this.hasPermission('NOTIFICATIONS');
  }

  hasPermission(permission: string): boolean {
    return this.authenticationService.hasPermission('global', 'HEADER__' + permission);
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/authentication/login']);
  }
}
