import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'truncate'
})
export class ScTruncatePipe implements PipeTransform {
    transform(value: string, limit: string, trail = '...'): string {
        const l = limit ? parseInt(limit, 10) : 10;
        const t = trail ? trail : '...';
        return value && value.length > l ? value.substring(0, l) + t : value;
    }
}
