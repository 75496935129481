import { stagger, trigger, transition, animate, query, style } from '@angular/animations';

export const tableListAnimation = trigger('tableListAnimation', [
    transition(':enter', [
        query('tr', [
            style({ opacity: 0, overflow: 'hidden' }),
            stagger(100, [
                animate('600ms ease-in-out', style({ opacity: 1 }))
            ])
        ])
    ])
]);
