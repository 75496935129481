import { Component, OnInit } from '@angular/core';

import { ScModalDialogService } from '@sc/claim-components';

import { AuthenticationService } from 'app/core/services/authentication.service';

@Component({
  selector: 'sc-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public hasLoginAgreementServiceAccess: boolean;
  public hasPlatformNewsAccess: boolean;

  constructor(
    private scModalDialogService: ScModalDialogService,
    public authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
    this.hasLoginAgreementServiceAccess = this.authenticationService.hasPermission('authentication', 'LOGIN__AGREEMENT_SERVICE');
    this.hasPlatformNewsAccess = this.authenticationService.hasPermission('authentication', 'PLATFORM_NEWS');
  }

  openServiceAgreementModal() {
    this.scModalDialogService.open('serviceAgreementModal');
  }

  openNews() {
    this.scModalDialogService.open('newsModal');
  }

    openDownloadBaremo() {
        this.scModalDialogService.open('downloadBaremoModal');
    }
}
