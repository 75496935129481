import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// RxJS
import { of } from 'rxjs';

import { map, catchError } from 'rxjs/operators';

import { ApiClient } from 'app/core/helpers/sc-api.helper';
import { environment } from 'environments/environment';
import { ClaimPASFile } from 'app/shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class SiniestrosService {
  destinationFolderURI: string;

  constructor(private api: ApiClient, public http: HttpClient) {}

  cancelServiceOrder(serviceOrderNumber) {
    return this.api.post(`${environment.ca7claim}/securityIntegration/serviceOrder/${serviceOrderNumber}`, {});
  }

  getActivities(data) {
    return this.api.get(environment.apiClaim + '/api/activities', data);
  }

  getClaims(data, branch = '') {
    branch = branch === 'car' ? '' : branch;
    return this.api.post(`${environment.apiClaim}/api/claims/${branch}`, data);
  }

  getClaimAdjusters(data) {
    return this.api.get(environment.apiClaim + '/api/claimadjusters', data);
  }

  getClaimAdjusterActivity(claimNumber) {
    return this.api.get(environment.apiClaim + '/api/claimadjusters/' + claimNumber);
  }

  getClaimAdjustersInvoiceList(stepStatus?: string) {
    const status = stepStatus ? `/${stepStatus}` : '';
    return this.api.get(`${environment.apiClaim}/api/claimadjusters/invoicelist${status}`);
  }

  getClaimAdjustersStatusList(stepStatus?: string) {
    const status = stepStatus ? `/${stepStatus}` : '';
    return this.api.get(`${environment.apiClaim}/api/claimadjusters/statuslist${status}`);
  }

  getClaimAdjustersTimeline(claimNumber) {
    return this.api.get(environment.apiClaim + '/api/claimadjusters/' + claimNumber + '/timeline');
  }

  getStatusList(stepStatus?: string) {
    const status = stepStatus ? `/${stepStatus}` : '';

    return this.api.get(environment.apiClaim + '/api/activities/statuslist' + status);
  }

  getBranchList() {
    return this.api.get(environment.apiClaim + '/api/claims/otherrisks/branches');
  }

  getPasInfo() {
    return this.api.get(`${environment.apiClaim}/api/pas/getpasinfo`);
  }

  getPasIndicatorsOR() {
    return this.api.get(`${environment.apiClaim}/api/pas/kpi/otherrisks`);
  }

  getPasIndicatorsCar() {
    return this.api.get(`${environment.apiClaim}/api/pas/kpi`);
  }

  getRelatedProducers() {
    return this.api.get(`${environment.apiClaim}/api/pas/relatedproducers`);
  }

  getSiniestroByClaimNumber(claimNumber) {
    return this.api.get(environment.apiClaim + '/api/claims/' + claimNumber);
  }

  getSiniestroByActivityId(activityId) {
    return this.api.get(environment.apiClaim + '/api/activities/' + activityId);
  }

  getStory(claimNumber) {
    return this.api.get(environment.apiClaim + '/api/claims/' + claimNumber + '/story');
  }

  getSiniestroDetails(claimNumber) {
    return this.api.get(environment.apiClaim + '/api/claims/' + claimNumber + '/detail');
  }

  getSiniestroDetailsOtherRisks(claimNumber) {
    return this.api.get(environment.apiClaim + '/api/claims/' + claimNumber + '/detail/otherrisks').pipe(
      map((res) => res),
      catchError((err) => {
        console.log('Error: ', err);
        return of(err);
      })
    );
  }

  getServiceOrderUrl(claimNumber: any) {
    return this.api.get(`${environment.ca7claim}/securityIntegration/serviceOrderUrl?claimNumber=${claimNumber}`);
  }

  serviceOrderValidation(claimNumber: any) {
    return this.api.get(`${environment.ca7claim}/securityIntegration/validation?claimNumber=${claimNumber}`);
  }

  checkAssignClaimCode(repairId) {
    return this.api.get(environment.apiClaim + '/api/claims/repair/' + repairId);
  }

  assignClaimById(repairId, data) {
    return this.api.post(environment.apiClaim + '/api/activities/repair/assign/' + repairId, data);
  }

  downloadCertificate(claimNumber) {
    return this.api.get(environment.apiClaim + '/api/claims/' + claimNumber + '/policy/certificate', null, { responseType: 'blob' });
  }

  downloadDeductibleLetter(claimNumber, claimOrder) {
    // tslint:disable-next-line:max-line-length
    return this.api.get(environment.apiClaim + '/api/claims/' + claimNumber + '/' + claimOrder + '/deductibleletter', null, {
      responseType: 'blob'
    });
  }

  downloadClaimReceipt(claimNumber) {
    return this.api.get(environment.apiClaim + '/api/claims/' + claimNumber + '/receipt', null, { responseType: 'blob' });
  }

  downloadPolicy(branchOffice, branch, policyNumber) {
    let url = environment.apiCommercialAutoUrl + '/api/Reports/policyWithoutClauses/';

    url += branchOffice + '/';
    url += branch + '/';
    url += policyNumber + '/';
    url += '1';

    return this.api.get(url, null, { responseType: 'blob', headers: { 'x-branchOffice': '' + parseInt(branchOffice, 10) } });
  }

  downloadDocument(url: string, headers: any): Observable<Blob> {
    if (headers) {
      return this.api.get(url, null, { responseType: 'blob', headers: headers });
    }
    return this.api.get(url, null, { responseType: 'blob' });
  }

  downloadPeritoPhotos(activityId): Observable<Blob> {
    return this.api.get(`${environment.apiClaim}/api/activities/${activityId}/files/claimadjuster/photos`, null, { responseType: 'blob' });
  }

  downloadPeritoBudgets(activityId): Observable<Blob> {
    return this.api.get(`${environment.apiClaim}/api/activities/${activityId}/files/claimadjuster/priceQuotes`, null, {
      responseType: 'blob'
    });
  }

  downloadServiceOrder(serviceOrderNumber): Observable<Blob> {
    return this.api.get(`${environment.apiClaim}/api/serviceorder/${serviceOrderNumber}`, null, { responseType: 'blob' });
  }

  getStatistics() {
    return this.api.get(environment.apiClaim + '/api/workshop/statistics');
  }

  getClaimAdjusterStatistics() {
    return this.api.get(environment.apiClaim + '/api/claimadjusters/statistics');
  }

  saveSiniestros(value) {
    window.localStorage.setItem('siniestros', JSON.stringify(value));
  }

  loadSiniestros() {
    return JSON.parse(window.localStorage.getItem('siniestros')) || [];
  }

  saveSiniestro(value) {
    const siniestros = this.loadSiniestros();
    const siniestroIndex = siniestros.findIndex((siniestro) => siniestro.activityId === value.activityId);

    if (siniestroIndex !== -1) {
      siniestros[siniestroIndex] = value;
    } else {
      siniestros.push(value);
    }

    this.saveSiniestros(siniestros);
  }

  loadSiniestro(parameters = {}) {
    const siniestros = this.loadSiniestros();
    return siniestros.filter((siniestro) => {
      const keys = Object.keys(parameters);
      let matchCount = 0;
      keys.forEach(function (key) {
        if (siniestro[key] === parameters[key]) {
          matchCount++;
        }
      });
      return matchCount === keys.length;
    })[0];
  }

  updateActivityContact(activityId, data) {
    return this.api.put(environment.apiClaim + '/api/activities/' + activityId + '/contact', data);
  }

  canAssign(): any {
    return this.api.get(`${environment.apiClaim}/api/activities/canAssign`);
  }

  getUsages(branch: string, claimNumber: string, claimType: string) {
    return this.api.get(`${environment.apiClaim}/api/pas/claimpasfiles/${claimType}/${claimNumber}/usages?branch=${branch}`);
  }

  saveFiles(claimNumber: string, files: Array<any>, claimType: string) {
    return this.api.post(`${environment.apiClaim}/api/pas/claimpasfiles/${claimType}/${claimNumber}/files/sendfilestoclaim`, files);
  }

  sendFraudEmail(claimType: string, data) {
    return this.api.post(`${environment.apiClaim}/api/claims/fraud/${claimType}/sendfraudalert`, data);
  }

  downloadReport(type: string): Observable<Blob> {
    let baseUrl = '/api/pas/kpi/xls';

    if (type === 'otherrisks') {
      baseUrl += '/otherrisks';
    }

    return this.api.get(`${environment.apiClaim}${baseUrl}`, null, { responseType: 'blob' });
  }

  shareDocumentation(documentationToShare: any, claimNumber, branch, shareType) {
    const shareTypeURL = shareType === 'whatsapp' ? '/' + shareType : '';
    documentationToShare.files = documentationToShare.files.map((document) => {
      document.url = `${environment.apiClaim}${document.url}`;
      return document;
    });

    return this.api.post(
      `${environment.apiClaim}/api/pas/claimpasfiles/${branch}/${claimNumber}/files/share${shareTypeURL}`,
      documentationToShare
    );
  }

  downloadDocumentBundle(files: ClaimPASFile[], branch: string, claimNumber: string): Observable<Blob> {
    return this.api.post(`${environment.apiClaim}/api/pas/claimpasfiles/${branch}/${claimNumber}/bundle`, files, { responseType: 'blob' });
  }

  getDestinationFolderURI() {
    return this.destinationFolderURI;
  }

  setDestinationFolderURI(destinationFolderURI) {
    this.destinationFolderURI = destinationFolderURI;
  }

  validateCuit(cuit: string, role: string) {
    return this.api.get(`${environment.apiAdmin}/api/User/role/${role}/tax/${cuit}`);
  }

  handlePayment(role: string, claimNumber: string, activityId: string) {
    return this.api.get(
      `${environment.apiClaim}/api/User/role/${role}/claimNumber/${claimNumber}/activityId/${activityId}`,
      {},
      { responseType: 'text' }
    );
  }
}
