import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scCurrency'
})
export class ScCurrencyPipe implements PipeTransform {

    transform(val: number): string {
        return this.setCurrencyFormat(val);
    }

    setCurrencyFormat(value) {
        const text = this.parse(value && typeof value === 'number' && value.toFixed(0) || value || 0);
        const newVal = (parseInt(text || '0', 10) || 0 ).toString();
        return '$ ' + this.addThousandsSeparator(newVal, '.');
    }

    parse(value) {
        const text = value && value.toString();
        return text && text.length && text !== '' ? text.toString().replace(/\D/g, '') : '0';
    }

    parseInt(value) {
        return parseInt(this.parse(value), 10);
    }

    addThousandsSeparator(n, thousandsSeparatorSymbol) {
        return n.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparatorSymbol);
    }
}
