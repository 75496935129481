import { ClaimAdjustmentStatus } from "./claim-adjustment-status.enum";
import { StatusIndicator } from "./status-indicator.enum";
import { ClaimContact } from 'app/shared/models/claimContact';

export class ManagementTracking {
  events: ClaimEvent[];
  claimAdjustmentStatus: ClaimAdjustmentStatus;
  lastManagement: string;
  contacts: ClaimContact[];
  administrator: ClaimContact;
}

export class ClaimEvent {
  id: string;
  date: Date;
  typeName: string;
  title: string;
  description: string;
  ownerName: string;
  statusIndicator: StatusIndicator;
}
