import { Action } from './action';
import { Administrator } from './administrator';
import { Dictionary } from './dictionary';
import { Insured } from './insured';
import { Person } from './person';
import { Risk } from './risk';
import { Task } from './task';
import { Workshop } from './workshop';
import { Contact } from './contact';
import { SparePartsView } from './sparePartsView';

export class Claim {
  public actions: Array<Action>;
  public activityId: string;
  public administrator: Administrator;
  public asignmentCode: string;
  public assignDate: Date;
  public belongToProducer: string;
  public branch: string;
  public branchOffice: string;
  public claimAdjuster: Person;
  public claimDate: Date;
  public claimLossCause: string;
  public claimNumber: string;
  public claimReporterFirstName: string;
  public claimReporterLastName: string;
  public claimReporterWay: string;
  public contact: Contact;
  public deductible: number;
  public exposure: string;
  public expressTracingStatus: string;
  public hasCloseStep: boolean;
  public hasPendingWorkOrder: boolean;
  public id: string;
  public insured: Insured;
  public isClosed: boolean;
  public isCurrent: boolean;
  public isDone: boolean;
  public isHidden: boolean;
  public isCLEAS: boolean;
  public isSubBranch: boolean;
  public overall: Dictionary<Task>;
  public pasEmail: string;
  public policyEndDate: Date;
  public policyNumber: string;
  public policyStartDate: Date;
  public policyType: string;
  public producer: Person;
  public product: string;
  public reporter: string;
  public risk: Risk;
  public safePolicyNumber: string;
  public sparePartsView: SparePartsView;
  public status: string;
  public timeline: Array<Task>;
  public type: string;
  public version: number;
  public workshop: Workshop;
  public workshopProfile: number;
  public workshopUser: string;

  constructor() {
    this.activityId = '';
    this.administrator = new Administrator();
    this.asignmentCode = '';
    this.assignDate = new Date();
    this.belongToProducer = '';
    this.branch = '';
    this.branchOffice = '';
    this.claimAdjuster = new Person();
    this.claimDate = new Date();
    this.claimLossCause = '';
    this.claimNumber = '';
    this.claimReporterFirstName = '';
    this.claimReporterLastName = '';
    this.claimReporterWay = '';
    this.contact = new Contact();
    this.deductible = null;
    this.exposure = '';
    this.expressTracingStatus = '';
    this.hasCloseStep = false;
    this.hasPendingWorkOrder = false;
    this.id = '';
    this.insured = new Insured();
    this.isClosed = false;
    this.isCurrent = false;
    this.isDone = false;
    this.isHidden = false;
    this.isCLEAS = false;
    this.isSubBranch = false;
    this.overall = {};
    this.pasEmail = '';
    this.policyEndDate = new Date();
    this.policyNumber = '';
    this.policyStartDate = new Date();
    this.policyType = '';
    this.producer = new Person();
    this.product = '';
    this.reporter = '';
    this.risk = new Risk();
    this.safePolicyNumber = '';
    this.sparePartsView = new SparePartsView();
    this.status = '';
    this.timeline = [];
    this.type = '';
    this.version = 1;
    this.workshop = new Workshop();
    this.workshopProfile = null;
    this.workshopUser = '';
  }
}
