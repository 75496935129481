import { ManagementTracking } from 'app/shared/models';
import { ManagementTrackingCard } from 'app/shared/models/management-tracking-card.interface';
import { ManagementTrackingCardStrategy } from './management-tracking-card.strategy';
import { ClaimDetailCardItem } from 'app/shared/models/claimDetail';
import { Injectable } from '@angular/core';

@Injectable()
export class LenderOwnersStrategy extends ManagementTrackingCardStrategy {
  getCard(managementTracking: ManagementTracking): ManagementTrackingCard {
    const trackingContacts = managementTracking.contacts;
    let trackingContactsMap =
      trackingContacts.length > 1
        ? { subtitle: `${trackingContacts.length} prestadores asignados` }
        : {
            subtitle: trackingContacts[0].fullName,
            subItems: [
              ClaimDetailCardItem.create('Email', trackingContacts[0].email),
              ClaimDetailCardItem.create('Tel', trackingContacts[0].phone)
            ]
          };

    return {
      subtitle: trackingContactsMap.subtitle,
      hasActionable: true,
      subItems: trackingContactsMap.subItems,
      lastItem: this.getLastItem(managementTracking)
    };
  }
}
