import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scCategoryImages',
    pure: false
})
export class ScCategoryImages implements PipeTransform {
    transform(text: string, active: boolean): any {
        switch (text) {
            case'A':
                return 'sc-icon-2x sc-tab-selector-icon__icon_2x ' + (active
                    ? 'icon-vehiculo_automovil active'
                    : 'icon-vehiculo_automovil');
            case'B':
                return 'sc-icon-3x sc-tab-selector-icon__icon_3x ' + (active ? 'icon-vehiculo_camion active' : 'icon-vehiculo_camion');
            case'C':
                return 'sc-icon-3x sc-tab-selector-icon__icon_3x ' + (active ? 'icon-vehiculo_acoplado active' : 'icon-vehiculo_acoplado');
            case'D':
                return 'sc-icon-3x sc-tab-selector-icon__icon_3x ' + (active ? 'icon-vehiculo_tractor active' : 'icon-vehiculo_tractor');
            default:
                return '';
        }
    }
}
