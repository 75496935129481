import { trigger, state, style, animate, transition } from '@angular/animations';

export const slide = trigger('slide', [
    state('void', style({
        height: 0,
        overflow: 'hidden'
    })),
    state('out', style({
        height: 0,
        overflow: 'hidden',
    })),
    transition('* <=> *', animate('300ms')),
]);
