import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { ScTableComponent } from './sc-table.component';
import { ScEmptyTableMessageComponent } from './sc-empty-table-message/sc-empty-table-message.component';

@NgModule({
    imports: [
        CommonModule
    ],
    exports: [
        ScEmptyTableMessageComponent,
        ScTableComponent
    ],
    declarations: [
        ScEmptyTableMessageComponent,
        ScTableComponent
    ]
})
export class ScTableModule { }
