import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'fileSize',
    pure: false
})
export class ScFileSizePipe implements PipeTransform {

    private units = [
        'bytes',
        'KB',
        'MB',
        'GB',
        'TB',
        'PB'
    ];

    transform(bytes = 0, precision = 2 ): string {
        if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) { return '?'; }

        let unit = 0;

        while ( bytes >= 1024 ) {
            bytes /= 1024;
            unit ++;
        }

        return bytes.toFixed( + precision ) + ' ' + this.units[ unit ];
    }
}
