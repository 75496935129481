import { Component, OnInit, Input, TemplateRef, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

// Animations
import { tableListAnimation, timelineListAnimation } from 'app/shared/animations';

@Component({
  selector: 'sc-table',
  templateUrl: './sc-table.component.html',
  styleUrls: ['./sc-table.component.scss'],
  animations: [tableListAnimation, timelineListAnimation],
  encapsulation: ViewEncapsulation.None
})
export class ScTableComponent implements OnInit {

  public placeholderItems: Array<any>;

  @Input() id: string;
  @Input() items: Array<any>;
  @Input() loading: boolean;
  @Input() placeholderItemsCount: number;
  @Input() rowHeaderTemplate: TemplateRef<any>;
  @Input() rowItemPlaceholderTemplate: TemplateRef<any>;
  @Input() rowItemTemplate: TemplateRef<any>;
  @Input() rowSelectable: boolean;

  @Output() rowSelected = new EventEmitter();

  ngOnInit() {
    this.placeholderItems = (this.placeholderItemsCount !== undefined) ? new Array(this.placeholderItemsCount) : new Array(10);
    if (this.rowSelectable === undefined) {
      this.rowSelectable = true;
    }
  }

  selectRow(item) {
    if (this.rowSelectable) {
      this.rowSelected.emit(item);
    }
  }
}
