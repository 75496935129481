export class Policies {
    public guid: string;
    public data?: any;
    public createdAt: Date;
    public updatedAt?: Date;

    constructor() {
        this.guid = '';
        this.data = null;
        this.createdAt = new Date();
        this.updatedAt = null;
    }
}
