import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'scDate',
    pure: false
})
export class ScDatePipe implements PipeTransform {

    private separador = '/';

    transform(val: string): string {

        if (typeof val !== 'string') {
            return val;
        }

        // Formato yyyy-mm-ddThh:ii:ss*
        const _regexp = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/i,
            _exec = _regexp.exec(val);
        let _ret;

        if (_exec) {
            _ret = _exec[3] + this.separador + _exec[2] + this.separador + _exec[1];
        }

        return _ret;
    }
}
