import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiCoreComponentsModule, ScModalDialogService } from '@sc/claim-components';

// Components
import { ScServiceAgreementModalComponent } from './sc-service-agreement-modal.component';

@NgModule({
  imports: [
    CommonModule,
    UiCoreComponentsModule
  ],
  exports: [
    ScServiceAgreementModalComponent
  ],
  declarations: [
    ScServiceAgreementModalComponent
  ],
  providers: [
    ScModalDialogService
  ]
})
export class ScServiceAgreementModalModule { }
