import { Injectable } from '@angular/core';
import { AppInsights } from 'applicationinsights-js';
import { environment } from 'environments/environment';

@Injectable()
export class AnalyticsService {

    private config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: environment.appInsightsKey
    };

    constructor() {
        if (!AppInsights.config) {
            AppInsights.downloadAndSetup(this.config);
        }
    }

    setGoogleAnalitycs() {
        const iframe = document.createElement('iframe');
        iframe.src = 'https://www.googletagmanager.com/ns.html?id=' + environment.google.taskManager.token;
        iframe.height = '0';
        iframe.width = '0';
        iframe.setAttribute('style', 'display:none;visibility:hidden;');

        /* tslint:disable */
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            let f = d.getElementsByTagName(s)[0], j: |any = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
            const b = document.body;
            b.appendChild(iframe)
        })(window, document, 'script', 'dataLayer', environment.google.taskManager.token);
        /* tslint:enable */
    }

    logPageView(name?: string, url?: string, properties?: any, measurements?: any, duration?: number) {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    }

    setAuthenticatedUser(authenticatedUserId: string, accountId?: string, storeInCookie = true) {
        AppInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
    }

    clearAuthenticatedUser() {
        AppInsights.clearAuthenticatedUserContext();
    }

    logEvent(name: string, properties?: any, measurements?: any) {
        AppInsights.trackEvent(name, properties, measurements);
    }

}
