import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'scPolicyStep',
    pure: false
})
export class ScPolicyStepPipe implements PipeTransform {
    transform(text: string): any {
        switch (text) {
            case'COTIZA_1': return 'Cotizacion';
            case'COTIZA_2': return 'Cotizacion';
            case'COBERT_1': return 'Cobertura';
            case'RESUMEN_1': return 'Resumen';
            case'EMISION_1': return 'Emision';
            case'EMISION_2': return 'Emision';
            case'EMISION_3': return 'Emision';
            case'EMISION_4': return 'Emision';
        }
    }
}
