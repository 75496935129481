import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiClient } from 'app/core/helpers';
import { AuthenticationService } from 'app/core/services';

@Injectable()
export class ScNotificationsService {

    constructor(
      private api: ApiClient,
      public authenticationService: AuthenticationService,
    ) {
    }

    getNotifications(data) {
      return this.api.get(environment.apiNotifications + '/api/notifications', data);
    }

    getUnreadNotifications(userName) {
      return this.api.get(environment.apiNotifications + '/api/notifications/' + userName + '/getunreadnotifications');
    }

    setNotificationAsReaded(notificationId) {
      return this.api.post(environment.apiNotifications + '/api/notifications/' + notificationId + '/markasread', {});
    }

    markAllClaimNotificationsAsRead(claimNumber, userName) {
      return this.api.post(`${environment.apiNotifications}/api/notifications/${claimNumber}/${userName}/markasread`, {});
    }
}
