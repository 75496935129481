import { Action } from './action';
import { TaskFile } from './task-file';

export enum TaskType {
    Verification = 'Verification',
    WorkOrder = 'WorkOrder',
    SpareParts = 'SpareParts',
    Appointment = 'Appointment',
    Repair = 'Repair',
    Extension = 'Extension',
    UserAgreement = 'UserAgreement',
    CheckIn = 'CheckIn',
    CheckOut = 'CheckOut'
}

interface IDownloable {
    downloadingFile: boolean;
}

export class Task implements IDownloable {
    public downloadingFile: boolean;
    public actions: Array<Action>;
    public activityId: number;
    public description: string;
    public descriptionDate: Date;
    public errorDescription: string;
    public files: Array<TaskFile>;
    public groupOrder: string;
    public groupPosition: number;
    public hasError: boolean;
    public hasTasks: boolean;
    public id: string;
    public isCurrent: boolean;
    public isDone: boolean;
    public isHidden: boolean;
    public parameters: any;
    public status: string;
    public statusType: string;
    public summaries: Array<any>;
    public timeline: Array<Task>;
    public title: string;
    public type: TaskType;

    constructor() {
        this.actions = [];
        this.activityId = null;
        this.description = '';
        this.descriptionDate = new Date();
        this.errorDescription = '';
        this.groupOrder = '';
        this.groupPosition = 1;
        this.hasError = false;
        this.hasTasks = false;
        this.id = '';
        this.isCurrent = false;
        this.isDone = false;
        this.isHidden = false;
        this.parameters = null;
        this.status = '';
        this.statusType = '';
        this.summaries = null;
        this.timeline = [];
        this.title = '';
        this.type = TaskType.Verification;
        this.downloadingFile = false;
    }
}
