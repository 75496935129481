export class Payment {
  accreditationDate: string;
  amount: string;
  beneficiaryName: string;
  name: string;
  paymentMethod: string;
  serviceType?: string;
  specialist?: string;
  number?: string;
  lossDate?: string;
}
