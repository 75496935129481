export class Contact {
  public name: string;
  public phoneNumber: string;
  public email: string;

  constructor() {
    this.name = '';
    this.phoneNumber = '';
    this.email = '';
  }
}
