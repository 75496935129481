import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Components
import { ScHeaderInfoComponent } from './header-info.component';

// Services
import { AuthenticationService } from 'app/core/services';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ScHeaderInfoComponent
  ],
  exports: [
    ScHeaderInfoComponent
  ],
  providers: [
    AuthenticationService
  ],
})
export class ScHeaderInfoModule { }
