import { Injector } from '@angular/core';
import { GridSettings } from '@sc/portal.fe.lib.ui-core-components';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceOrder } from 'app/shared/models/service-orders-by-claim-number-response.model';

export class GridSettingsResult {
  public settings: GridSettings;
  public subject: BehaviorSubject<Array<any>>;
  public subjectSetter: Function;
  public title: string;
  public hasToShow?: Function;
  public dynamicComponent?;
  public getDynamicComponentInjector?: (claimPASDetail) => Injector;
  public getEvent?: Observable<ServiceOrder>;
}
