<sc-modal-dialog class="sc-service-agreement-modal" id="serviceAgreementModal" [scModalDialogConfig]="scServiceAgreementModalConfig" (onOpen)="getUserInfo()">
    <section class="sc-service-agreement-document" [ngSwitch]="true">
        <div *ngSwitchCase="userInfo.userRole === 'PeritoInterno' || userInfo.userRole === 'PeritoExterno'">

            <h4 class="title">Perito Automotor Red San Cristóbal Seguros</h4>
            <h4 class="sub-title mb-1">TÉRMINOS Y CONDICIONES</h4>

            <article class="resume">
                <section>
                    Los siguientes términos y condiciones detallan el Acuerdo de Servicio y/o contrato de locación de servicios, entre San Cristóbal y usted (en adelante el proveedor) que rigen el uso que usted le dé a este sitio web y el servicio que debe cumplir, política de oferta de servicio y cláusulas de confidencialidad.
                </section>
                <section>
                    <p>
                        <strong>AL USAR ESTE SITIO WEB, USTED ACEPTA Y ESTA DE ACUERDO CON ESTOS TÉRMINOS Y CONDICIONES EN LO QUE SE REFIERE A SU USO Y LAS OBLIGACIONES QUE EMANAN DE SU USO Y DEL SERVICIO QUE PRESTA PARA SAN CRISTOBAL SEGUROS.</strong>
                    </p>
                    <p>
                        En su carácter de prestador de servicios de San Cristóbal, deberá brindar en todo momento el mejor trato, cordial y respetuoso hacia el asegurado/ tercero, protegiendo la imagen de San Cristóbal como marca referente en el mercado.
                    </p>
                    <p>
                        Prestará sus servicios cumpliendo todas las normas legales y éticas inherentes a su actividad.
                    </p>
                    <p>
                        Deberá cumplir por sí todas las tareas y diligencias inherentes a su cometido, no podrá delegarlas en terceros.
                    </p>
                    <p>
                        Gestionará los casos que le asignen en el menor tiempo, al menor costo posible y alineado a los plazos definidos en el presente documento.
                    </p>
                    <p>
                        Adoptará las medidas necesarias para el éxito de los casos que le sean encomendados, obligándose a la reparación de cualquier daño o perjuicio que sean causados o derivados de su omisión, acción inadecuada o falta de atención profesional.
                    </p>
                    <p>
                        Mantendrá el debido secreto profesional en todos los casos bajo su gestión.
                    </p>
                    <p>
                        Deberá poner en conocimiento aquellas conductas y/o situaciones que a su juicio importen serios indicios de fraude al sector de Anti Fraude de la compañía vía correo electrónico al Analista tramitador de la sucursal que corresponda.
                    </p>
                    <p>
                        El perito mecánico recibirá en su bandeja de trabajo, dentro de la presente plataforma las inspecciones realizadas por los Talleres mecánicos asociados y en paralelo, un correo electrónico de la Plataforma de Siniestros Solución Express. Una vez analizada la documentación (presupuestos y fotos) procederá a realizar la peritación, en base al monto del siniestro reclamado y al tipo de daño.
                    </p>
                    <p>
                        Gestionará los casos que le asignen en el menor tiempo, al menor costo posible y alineado a los plazos definidos en el presente documento.
                    </p>
                    <p>
                        Cada pericia mecánica se realizará a través de Orión Cesvicom, informando los datos mandatorios que permitirán tener una identificación completa del vehículo siniestrado conforme a los establecido en el acuerdo de Servicio oportunamente firmado para todas las pericias a realizas en CESVI.
                    </p>
                </section>
            </article>

            <article class="points">
                <section>
                    <p>
                        <strong>Una vez realizada la pericia, deberá confeccionar la Órdenes de Mano de Obra y Repuestos y deberá adjuntarlas dentro de la plataforma para que el taller pueda tomar conocimiento de ellas.</strong>
                    </p>
                    <ul class="list-circle">
                        <li>
                            <strong>Sólo Orden Mano de Obra:</strong>
                            <span>
                                emitirá la orden de mano de obra a través de Orión dentro de las <strong>48 horas hábiles</strong> de iniciada la peritación e <u>indicará al Taller el correo electrónico del Administrador de la exposición</u> a fines de que el proveedor remita la factura correspondiente.
                            </span>
                        </li>
                        <li>
                            <strong>Órdenes de mano de Obra y Repuestos:</strong>
                            <span>
                                emitirá las órdenes de mano de obra y repuestos dentro de las <strong>48 horas hábiles</strong> de iniciada la peritación informando sobre lo actuado e <u>indicará el correo electrónico del Administrador de la exposición</u> a fines de que el proveedor remitirá las facturas correspondientes.
                            </span>
                        </li>
                        <li>
                            <strong>Licitación:</strong>
                            <span>
                                Licitará los repuestos vía Orión adquiriendo los de calidad original de menor costo y con una apertura máxima de dos proveedores, considerando un plazo de entrega no mayor a los <strong>7 días hábiles</strong> desde la fecha de licitación.
                            </span>
                        </li>
                    </ul>
                    <p class="mt-1">En caso de existir compra de repuestos directa deberá dejar asentado esto en observaciones dentro de la pericia (ejemplo: compra en concesionario).</p>
                    <p>
                        <strong>Por último, deberá adjuntar la factura de honorarios por sus servicios prestados, únicamente dentro de la plataforma. Esta aseguradora, ha dispuesto que este es el único canal de comunicación y gestión para las pericias de daños parciales de Solución Express, no aceptando gestiones por fuera de esta herramienta. Para tal caso evaluara sanciones y/o rescisión del presente acuerdo.</strong>
                    </p>
                </section>
                <section>
                    <h3>CONFINDENCIALIDAD</h3>
                    <p>San Cristóbal entregará acceso a Información Confidencial sobre siniestros de asegurados y proveedores de San Cristóbal a efectos de que la analice y preste los referidos.</p>
                    <p>El término "Información Confidencial", comprende todas las comunicaciones realizadas por cualquiera de las Partes a la otra y a sus respectivos, funcionarios, directores, empleados, mandatarios y/o asesores independientes, ya sea en forma oral o escrita, aun en el caso de que tal información no sea identificada o señalada como “Confidencial”, “Privada" o una designación de índole similar.</p>
                    <p>No se realizará ninguna divulgación pública del presente documento a menos que la misma sea aprobada por ambas Partes, excepto en la medida en que tal divulgación sea exigida por la ley aplicable (incluyendo las normas y regulaciones del mercado de valores en el que coticen los títulos valores de tal Parte) o fuera exigida por una entidad gubernamental o bolsa de valores, en cuyo caso, la Parte que debe hacer la divulgación le otorgará a la otra Parte, en la medida de lo posible, un tiempo razonable para formular comentarios respecto de tal publicación o anuncio con anterioridad a su emisión; con posterioridad a tal divulgación, cada Parte podrá realizar anuncios internos a sus respectivos empleados, o realizar otra divulgación pública que considere apropiada a su entera discreción a otras personas, incluyendo a analistas de títulos e inversores institucionales, como así también en entrevistas con la prensa que, en todos los casos, deberán ser congruentes con las divulgaciones públicas anteriores de las Partes en relación con el presente Convenio.</p>
                    <p>Las Partes aclaran que el presente No implica relación societaria entre el proveedor y San Cristóbal, o relación societaria o laboral entre San Cristóbal y el personal eventualmente dependiente o contratado del proveedor, ni que alguna de las partes tiene poder para representar a la otra, fuera de lo previsto expresamente en este Convenio.</p>
                    <p>El uso por parte del proveedor de las marcas, logotipos e isotipos de San Cristóbal, así como de cualquier otro elemento y/o material que sea propiedad intelectual de San Cristóbal en el marco del presente Convenio, no le otorgará a ningún derecho sobre los mismos, siendo San Cristóbal la única y exclusiva propietaria de ellos. </p>
                    <p>El proveedor no podrá divulgar, compartir, trasferir, prestar las claves y usuarios de accesos, a la Plataforma de Siniestros.</p>
                    <p>Las obligaciones contenidas en el presente Convenio tendrán una vigencia de diez (10) años.</p>
                    <p>El presente Contrato se rige por las leyes de la República Argentina. Las Partes aceptan por el presente la competencia de los Juzgados Nacionales en lo Comercial de la Ciudad de Rosario, renunciando a cualquier excepción que pudiera oponer por improcedencia de fuero.</p>
                    <p>El presente acuerdo no invalida ni rescinde Acuerdos de Servicios firmados por otros procesos periciales, diferentes al de Solución Express.</p>
                    <p><strong>POR LO EXPUESTO, ACEPTO LOS TÉRMINOS Y CONDICIONES ESPECIFICADOS PRECEDENTEMENTE.</strong></p>
                </section>
            </article>

        </div>
        <div *ngSwitchDefault>

            <h4 class="title">Talleres red San Cristóbal</h4>
            <h4 class="sub-title mb-1">TÉRMINOS Y CONDICIONES</h4>

            <article class="resume">
                <section>
                    Los siguientes términos y condiciones detallan el Acuerdo de Servicio y/o contrato de
                    locación de servicios, entre San Cristóbal
                    y usted (en adelante el proveedor) que rigen el uso que usted le dé a este sitio web y el
                    servicio
                    que debe cumplir, política de oferta de servicio y cláusulas de confidencialidad.
                </section>
                <section>
                    <strong>
                        AL USAR ESTE SITIO WEB, USTED ACEPTA Y ESTA DE ACUERDO CON ESTOS TÉRMINOS Y CONDICIONES
                        EN LO QUE SE REFIERE A SU USO Y LAS
                        OBLIGACIONES QUE EMANAN DE SU USO Y DEL SERVICIO QUE PRESTA PARA SAN CRISTOBAL SEGUROS.
                    </strong>
                </section>
            </article>

            <article class="points">
                <section>
                    <h3>1. Objetivo</h3>
                    <p>Atender, asesorar y gestionar proactivamente los siniestros asignados, con el objetivo
                        de
                        colaborar en el proceso de reparación de un vehículo asegurado, en el menor tiempo y
                        costo
                        posible.</p>
                    <p>Establecer los estándares de Servicio y procesos de control que deberán regir la
                        prestación
                        del Servicio del Proveedor de San Cristóbal.</p>
                </section>
                <section>
                    <h3>2. Alcance</h3>
                    <p>Verificar y reparar los vehículos provenientes de un siniestro. Reparando integralmente
                        los
                        daños sufridos, hasta la culminación de las tareas, logrando la conformidad del
                        asegurado,
                        con respecto al servicio y calidad del producto finalmente entregado.</p>
                </section>
                <section>
                    <h3>3. Condiciones Generales del Servicio</h3>
                    <p>En su carácter de prestador de servicios de San Cristóbal, deberá brindar en todo
                        momento
                        el mejor trato, cordial y respetuoso hacia el asegurado y tramitadores, protegiendo la
                        imagen
                        de San Cristóbal como marca referente en el mercado.</p>
                    <ul class="list">
                        <li>Prestará sus servicios cumpliendo todas las normas legales y éticas inherentes a su
                            actividad.</li>
                        <li>Deberá cumplir por sí todas las tareas y diligencias inherentes a su cometido. En
                            caso
                            de tratarse de una Empresa (persona jurídica) podrá delegar la tarea, bajo su
                            propia
                            responsabilidad.
                        </li>
                        <li>Gestionará los casos que le asignen en el menor tiempo posible, al menor costo
                            posible
                            y alineado a los plazos definidos en el presente documento.</li>
                        <li>El prestador deberá poseer personal profesional, técnico e idóneo para la gestión d
                            reparaciones.</li>
                    </ul>
                </section>
                <section>
                    <h3>4. Asignación de Casos</h3>
                    <p>La asignación de los casos se dará al momento que el Asegurado ingrese con su vehículo
                        al
                        taller correspondiente a la red San Cristóbal para presupuestar los daños ocasionados
                        como
                        consecuencia de un siniestro y que los mismos guarden relación de causalidad entre el
                        hecho
                        denunciado y los daños.</p>
                </section>
                <section>
                    <h3>5. Gestión del Proveedor – Fotografías</h3>
                    <p>Al momento de recibir el vehículo, el taller deberá realizar la valuación de los daños
                        del
                        mismo, fotografiando los daños materiales del vehículo siniestrado.</p>
                    <p>Para la confección del presupuesto deberá tener en cuenta los siguientes estándares para
                        la
                        toma de las fotografías:</p>
                    <ul class="list">
                        <li>Todos los repuestos reclamados para ser reemplazados, tendrá su correspondiente
                            foto
                            (es factible que varios repuestos se puedan justificar con una sola fotografía).</li>
                        <li>Todas las imágenes deberán estar fechadas.</li>
                        <li>Deberán tomar 2 imágenes que muestren los vértices opuestos del vehículo para que
                            se
                            vean los 4 lados del mismo.</li>
                        <li>Foto número del chasis.</li>
                        <li>Todas las piezas reparadas/pintadas deber tener la correspondiste foto que sustente
                            el
                            daño reclamado.
                        </li>
                    </ul>
                </section>
                <section>
                    <h3>6. Confección Presupuesto</h3>
                    <p>Una vez realizada la evaluación al vehículo siniestrado y estimado el daño deberá
                        ingresar
                        a la Plataforma de Siniestros, en adelante PS (ingresando a sancristobal.com.ar /
                        portal
                        de talleres) con su usuario y clave, buscar el siniestro asignado (cambiará cuándo
                        pueda
                        realizar la búsqueda de siniestros y autoasignarse) que corresponda y adjuntar
                        formulario
                        de presupuesto y las fotos con las características informadas precedentemente.</p>
                    <div class="box">
                        <p>
                            <strong>IMPORTANTE:</strong> El taller deberá completar el presupuesto de la reparación al
                            menos
                            que el daño sea una destrucción Total Evidente (un incendio total del vehículo, o
                            un
                            daño de tal magnitud que sea técnicamente irreparable). Si el asegurado consulta
                            sobre
                            la determinación deberá responder que se ponga en contacto con la Compañía.</p>
                    </div>
                </section>
                <section>
                    <h3>7. Envío de Presupuesto a Posición Remota</h3>
                    <p>La posición remota recibirá en forma automática el presupuesto cuando el taller lo
                        complete
                        por PS.
                    </p>
                    <div class="box">
                        <p>El presupuesto bajo ninguna circunstancia podrá ser entregado al Asegurado en
                            ninguna
                            de las instancias de reparación.</p>
                    </div>
                </section>
                <section>
                    <h3>8. Recepción de órdenes de trabajo y repuestos</h3>
                    <p>El perito remoto asignado recibirá los presupuestos y fotos remitidas, procediendo a
                        confeccionar
                        la pericia correspondiente vía CESVI Orión y generando las órdenes según corresponda.</p>
                    <p>Al recibir las órdenes de trabajo vía correo electrónico, deberá acceder a la PS e
                        informar
                        su recepción:
                    </p>
                    <ul class="list">
                        <li>
                            <strong>Reparación sin repuestos:</strong> procederá a consignar el dato en la PS y se
                            contactará
                            con el asegurado para coordinar turno.</li>
                        <li>
                            <strong>Reparación con repuestos:</strong> procederá a consignar la necesidad de repuestos y
                            pasar
                            al siguiente estado para completar cuándo lleguen las piezas.</li>
                    </ul>
                </section>
                <section>
                    <h3>9. Recepción de repuestos</h3>
                    <p>Recibidos los repuestos por parte del proveedor, deberá ingresar a la PS, buscar el
                        siniestro
                        que corresponda e informar la recepción de los mismos:</p>
                    <ul class="list">
                        <li>
                            <strong>Repuestos correctos:</strong> deberá completar fecha de recepción, adjuntar el
                            documento
                            de remito e inmediatamente contactarse con el cliente para acordar turno de ingreso
                            de
                            la unidad al taller para iniciar la reparación.</li>
                        <li>
                            <strong>Repuestos incorrectos:</strong> deberá ingresar a la PS buscar el siniestro que
                            corresponda
                            y dentro del estado pendiente, deberá consignar en observaciones las dificultades
                            encontradas.
                            Asimismo, deberá tomar contacto con el proveedor a fin de resolver la situación.
                        </li>
                    </ul>
                    <div class="box">
                        <p>Todo dato ingresado en observaciones, llegara un aviso automático al Tramitador
                            informando
                            textualmente lo asentado.</p>
                    </div>
                    <div class="box">
                        <p>En las reparaciones en donde existan repuestos el Proveedor deberá tener en cuenta
                            las
                            siguientes categorías de repuestos para la red de talleres homologados San
                            Cristóbal:</p>
                        <ul class="list">
                            <li>Legitimo; repuesto proveniente directamente de fábrica, en caja de la marca Ej:
                                una
                                óptica Valeo en Caja Renault.</li>
                            <li>No Legitimo: (original): en el mismo repuesto definido en Legitimo, pero fuera
                                de
                                la caja de la marca, por Ej.: una óptica Valeo en caja Valeo.</li>
                            <li>Alternativo: cualquier repuesto que no califique en una de las dos definiciones
                                anteriores,
                                por Ej, una óptica VIC</li>
                        </ul>
                    </div>
                    <div class="box">
                        <p>
                            <strong>IMPORTANTE:</strong> Los repuestos deberán ser nuevos, no se aceptan reparados, etc.</p>
                    </div>
                    <p>
                        <strong>El taller asume el compromiso de dejar a disposición dela Compañía en el taller los
                            repuestos
                            remplazados mientras el vehículo no se haya entregado.</strong>
                    </p>
                    <p>Las piezas reemplazadas clasificadas como LEGITIMOS, deberán estibarse dentro de la
                        misma
                        caja/bolsa en donde recibieron, en caso que esto entorpezca la operación del taller por
                        un
                        tema de volumen, se solicita que al menos se desprenda y se adhiera a la pieza, la
                        calcomanía
                        con el código de barras.</p>
                    <p>Indicará en el remito, en cada uno de los reglones, si se trata de un repuesto legitimo
                        o
                        no legítimo.
                    </p>
                    <p>En caso que el proveedor no indique ninguna de las dos calidades anteriores, se sumirá
                        que
                        se trata de un repuesto ALTERNATIVO.</p>
                    <p>El taller multimarca deberá exigir al proveedor de repuestos este remito y controlar que
                        cada
                        repuesto recibido este debidamente detallado, luego conformarlo, firmado y guardar una
                        copia.
                    </p>
                    <div class="box">
                        <p>
                            <strong>IMPORTANTE:</strong> Si al momento de la entrega por parte del repuestero, el taller
                            detecta
                            que hay repuestos que no coinciden con la Calidad descripta en el remito, el taller
                            no
                            aceptará esos repuestos e informará a la posición remota de esta situación</p>
                    </div>
                </section>
                <section>
                    <h3>10. Asignación de Turnos</h3>
                    <p>El taller estará en condiciones de iniciar la reparación, una vez que San Cristóbal haya
                        autorizado
                        la misma mediante la Orden de Reparación.</p>
                    <p>
                        <strong>El Proveedor deberá coordinar con el asegurado un turno dentro de los 72 hs. días
                            hábiles
                            de recibido el presupuesto aprobado y en caso de que la reparación implique
                            repuestos
                            a la recepción de estos.</strong>
                    </p>
                </section>
                <section>
                    <h3>11. Ingreso al Taller para reparación de la unidad Asegurada.</h3>
                    <p>Una vez ingresado el vehículo para proceder a su reparación deberá ingresar a la PS,
                        buscar
                        el siniestro que corresponda y consignar aviso de ingreso de vehículo para reparación y
                        completará
                        la fecha estimada de finalización de reparación.</p>
                </section>
                <section>
                    <h3>12. Prevención de detección del Fraude.</h3>
                    <p>Cualquier indicio de fraude que pudiera observar el taller homologado respecto a un caso
                        que
                        estén gestionado deberá ser comunicado inmediatamente vía mail a
                        dto_fraudes@sancristobal.com.ar
                        con copia al Responsable de Gestión de Proveedores.</p>
                </section>
                <section>
                    <h3>13. Garantía.</h3>
                    <p>El taller dará garantía en toda reparación de chapa, pintura y mecánica, abordando la
                        reparación
                        efectuada y los materiales utilizados.</p>
                    <p>La garantía no contemplara vicios propios o desgastes como consecuencia del uso del
                        vehículo.</p>
                    <p>Cualquier reclamo posterior al arreglo del Asegurado directo al taller, será derivado de
                        manera
                        inmediata a la Posición Remota con copia al taller, quien responderá dentro de las 48
                        hs.
                        Hábiles.
                    </p>
                    <div class="box">
                        <p><strong>IMPORTANTE:</strong> si la reparación incluye repuestos, se trasladará la garantía que
                            otorgue el fabricante.</p>
                    </div>
                </section>
                <section>
                    <h3>14. Entrega de Vehículo, conformidad del socio y Facturación.</h3>
                    <p>Finalizada la reparación de la unidad asegurada, deberá hacer firmar al socio la
                        conformidad
                        del servicio y adjuntarla a la PS. Para lo cual ingresará a dicha plataforma, buscará
                        el
                        siniestro que corresponda y completará el ultimo estado “ENTREGA Y CONFORMIDAD”
                        adjuntando
                        el archivo.</p>
                    <p>El taller deberá adjuntar a la PS la factura dentro de las 48 hs posteriores de haber
                        firmado
                        el conforme por parte del Asegurado. Para lo cual, ingresará a la PS, buscará y
                        seleccionará
                        el siniestro indicado y adjuntará la misma.</p>
                    <p>Por último, deberá adjuntar copia del remito de los repuestos entregados.</p>
                </section>
                <section>
                    <h3>15. Auditorias.</h3>
                    <p>San Cristóbal realizara auditorias técnicas para garantizar la calidad del servicio
                        cuando
                        lo considere oportuno.</p>
                    <p>El taller deberá proporcionar al auditor de San Cristóbal todos los elementos que este
                        le
                        solicite.</p>
                    <p>En caso de conformidad respecto a ajustes que pudiera realizar el auditor, firmará la
                        auditoria
                        y aceptará los resultados de la misma. Si tuviera alguna disidencia podrá enviar la
                        misma
                        junto sus comentarios a
                        <a href="mailto: sicoffm@sancristobal.com.ar">sicoffm@sancristobal.com.ar</a> y
                        <a href="mailto: marconif@sancristobal.com.ar">marconif@sancristobal.com.ar</a>
                    </p>
                </section>
                <section>
                    <h3>16. Revisiones – Análisis de Performance</h3>
                    <p>A fin de garantizar el mejor servicio y experiencia del usuario, cuándo el Proveedor
                        infrinja
                        las pautas de este acuerdo o no cumpla con las medidas dispuestas en el presente
                        acuerdo,
                        y de ello resulte el ejercicio anormal de la operatoria, será pasible DE sanciones y/o
                        suspensión
                        por tiempo indeterminado del servicio.</p>
                    <div class="box">
                        <strong>ACLARACIÓN:</strong> Quedaran exceptuados de estas sanciones aquellos casos generados por
                        inconvenientes
                        técnicos de sistemas y/o indisposiciones particulares del profesional (Ejemplo:
                        enfermedad).
                    </div>
                </section>
            </article>

            <article class="letter">
                <section>
                    <h3>CARTA OFERTA POR PRESTACION DE SERVICIO</h3>
                    <p>El Proveedor se obliga a prestar los servicios solicitados por SAN CRISTOBAL S.M.S.G.</p>
                    <p>Los servicios mencionados serán realizados por el Proveedor, en forma personal o con sus
                        dependientes.</p>
                    <p>El Proveedor manifiesta contar con todos los medios idóneos y necesarios para cumplir
                        con los
                        servicios objeto de la presente Carta Oferta y se obliga a comunicar inmediatamente y
                        en forma
                        fehaciente a SAN CRISTOBAL S.M.S.G cuándo no se encuentre en condiciones de
                        realizarlos.</p>
                    <p>Se podrá rescindir el acuerdo emergente de la presente Carta Oferta en cualquier momento
                        de su
                        vigencia, sin expresión de causa y sin derecho a compensación o indemnización alguna,
                        por ningún
                        concepto, a favor de la otra.</p>
                    <p>El Proveedor mantendrá indemne a SAN CRISTOBAL S.M.S.G. por cualquier reclamo que esta
                        última
                        reciba a consecuencia de daños ocasionados en oportunidad de la prestación del servicio
                        aquí
                        ofrecido, así como cualquier otro reclamo que se produzca por el actuar negligente o
                        por impericia
                        del Proveedor, sean dichos reclamos formulados por los clientes de SAN CRISTOBAL
                        S.M.S.G o por
                        cualquier otra persona. Quedan excluidos aquellos daños ocasionados por razones de caso
                        fortuito
                        o fuerza mayor, como aquellos derivados por hechos de terceros.</p>
                    <p>La aceptación de la presente Carta Oferta y el acuerdo emergente de su aceptación no
                        implica asociación
                        de las partes bajo ninguna forma, ni la constitución de una sociedad, sucursal o joint
                        venture,
                        más allá de la obligación de cumplimiento de las prestaciones a cargo de cada una de
                        las partes,
                        según surgen de la presente</p>
                    <p>SAN CRISTOBAL S.M.S.G. y el Proveedor reconocen que no existe relación o vinculación
                        laboral alguna
                        entre ellas, quedando sobreentendido que los empleados, dependientes y cualquier otro
                        personal
                        y/o franquiciado y/o agente y/o empleados de éstos que posea actualmente o se incorpore
                        en el
                        futuro no podrán ser nunca considerados empleados de la otra parte, siendo
                        exclusivamente a cargo
                        de cada una de las partes el cumplimiento de todas las obligaciones emergentes de las
                        leyes laborales,
                        convenios, acuerdos de trabajo, leyes previsionales y de seguridad social, enfermedades
                        y accidentes
                        de trabajo.</p>
                    <p>El deber de indemnidad continuará vigente, aún hasta después de la terminación de la
                        vigencia
                        del acuerdo emergente de la aceptación de la presente, si la causa del reclamo tuvo
                        origen durante
                        el transcurso del mismo.</p>
                    <p>La presente Carta Oferta y el acuerdo emergente de su aceptación no implica exclusividad
                        para
                        ninguna de las partes; de manera tal, que el Proveedor puede prestar (y de hecho
                        manifiesta que
                        lo está haciendo a la fecha del presente y continuará en el futuro) los mismos u otros
                        servicios
                        aquí ofrecidos, a favor de cualquier otra persona física o jurídica distinta a SAN
                        CRISTOBAL
                        S.M.S.G. y, esta última, por su parte, podrá contratar idénticos servicios a los aquí
                        ofrecidos,
                        con otro proveedor.
                    </p>
                    <p>El Taller podrá percibir el monto de la franquicia únicamente cuándo SAN CRISTOBAL
                        S.M.S.G expresamente
                        así lo autorice, indicando el monto a percibir por el taller.</p>
                </section>
            </article>

            <article class="confidentiality">
                <section>
                    <h3>CONFIDENCIALIDAD</h3>
                    <p>El término "Información Confidencial", comprende todas las comunicaciones realizadas por
                        cualquiera
                        de las Partes a la otra y a sus respectivos, funcionarios, directores, empleados,
                        mandatarios
                        y/o asesores independientes, ya sea en forma oral o escrita, aun en el caso de que tal
                        información
                        no sea identificada o señalada como “Confidencial”, “Privada" o una designación de
                        índole similar.
                    </p>
                    <p>El proveedor no podrá divulgar, compartir o transferir información referida a los
                        siniestros en
                        los que intervenga (asegurados, terceros, proveedores intervinientes, etc.)</p>
                    <p>No se realizará ninguna divulgación pública del presente documento a menos que la misma
                        sea aprobada
                        por ambas Partes, excepto en la medida en que tal divulgación sea exigida por la ley
                        aplicable
                        (incluyendo las normas y regulaciones del mercado de valores en el que coticen los
                        títulos valores
                        de tal Parte) o fuera exigida por una entidad gubernamental o bolsa de valores, en cuyo
                        caso,
                        la Parte que debe hacer la divulgación le otorgará a la otra Parte, en la medida de lo
                        posible,
                        un tiempo razonable para formular comentarios respecto de tal publicación o anuncio con
                        anterioridad
                        a su emisión; con posterioridad a tal divulgación, cada Parte podrá realizar anuncios
                        internos
                        a sus respectivos empleados, o realizar otra divulgación pública que considere
                        apropiada a su
                        entera discreción a otras personas, incluyendo a analistas de títulos e inversores
                        institucionales,
                        como así también en entrevistas con la prensa que, en todos los casos, deberán ser
                        congruentes
                        con las divulgaciones públicas anteriores de las Partes en relación con el presente
                        Convenio.</p>
                    <p>Las Partes aclaran que el presente No implica relación societaria entre el proveedor y
                        San Cristóbal,
                        o relación societaria o laboral entre San Cristóbal y el personal eventualmente
                        dependiente o
                        contratado del proveedor, ni que alguna de las partes tiene poder para representar a la
                        otra,
                        fuera de lo previsto expresamente en este Convenio.</p>
                    <p>El uso por parte del proveedor de las marcas, logotipos e isotipos de San Cristóbal, así
                        como
                        de cualquier otro elemento y/o material que sea propiedad intelectual de San Cristóbal
                        en el
                        marco del presente Convenio, no le otorgará a ningún derecho sobre los mismos, siendo
                        San Cristóbal
                        la única y exclusiva propietaria de ellos.</p>
                    <p>
                        <strong>El proveedor no podrá divulgar, compartir, trasferir, prestar las claves y usuarios
                            de accesos,
                            a la Plataforma de Siniestros.</strong>
                    </p>
                    <p>Las obligaciones contenidas en el presente Convenio tendrán una vigencia de diez (10)
                        años.</p>
                    <p>El presente Contrato se rige por las leyes de la República Argentina. Las Partes aceptan
                        por el
                        presente la competencia de los Tribunales Provinciales de la Ciudad de Rosario,
                        renunciando a
                        cualquier excepción que pudiera oponer por improcedencia de fuero.</p>
                    <p class="mt-1">
                        <strong>POR LO EXPUESTO, ACEPTO LOS TÉRMINOS Y CONDICIONES ESPECIFICADOS PRECEDENTEMENTE.</strong>
                    </p>
                </section>
            </article>

        </div>
    </section>
</sc-modal-dialog>
