
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { JwtHelperService } from '@auth0/angular-jwt';

// RxJS
import { throwError as observableThrowError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

// Services
import { AnalyticsService } from 'app/core/services/analytics.service';

// Helpers
import { ScBrowserDetectHelper } from 'app/core/helpers/sc-browser-detect.helper';


@Injectable() export class ApiClient {

    private httpOptions: any;

    private tk: any;
    private user: any;

    public apiErrorMessage: any;

    constructor(
        public httpClient: HttpClient,
        public jwtHelperService: JwtHelperService,
        public analyticsService: AnalyticsService,
        public scBrowserDetectHelper: ScBrowserDetectHelper
    ) { }

    get(url: string, data: Object = null, httpOptions: Object = {}) {
        this.createHttpOptions(httpOptions);
        if (data != null) {
            url = url + '?' + this.getUrlQueryParamsString(data);
        }
        return this.httpClient.get(url, this.httpOptions).pipe(
            map((res: any) => {
                if (res && res.status < 200 && res.status > 299) {
                    observableThrowError(() => res);
                }
                return res;
            }),
            catchError(this.handleError.bind(this))
        );
    }


    delete(url: string, httpOptions: Object = {}) {
        this.createHttpOptions(httpOptions);
        return this.httpClient.delete(url, this.httpOptions).pipe(
            map((res: any) => {
                if (res && res.status < 200 && res.status > 299) {
                    return observableThrowError(() => res.statusText);
                }
                return res;
            }),
            catchError(this.handleError.bind(this))
        );
    }

    post(url: string, data: any, httpOptions: Object = {}) {
        this.createHttpOptions(httpOptions);
        return this.httpClient.post(url, data, this.httpOptions).pipe(
            map((res: any) => {
                if (res && res.status < 200 && res.status > 299) {
                    return observableThrowError(() => res.statusText);
                }
                return res;
            }),
            catchError(this.handleError.bind(this))
        );
    }

    put(url: string, data: any, httpOptions: Object = {}) {
        this.createHttpOptions(httpOptions);
        return this.httpClient.put(url, data, this.httpOptions).pipe(
            map((res: any) => {
                if (res && res.status < 200 && res.status > 299) {
                    return observableThrowError(() => res.statusText);
                }
                return res;
            }),
            catchError(this.handleError.bind(this))
        );
    }

    patch(url: string, data: any, httpOptions: Object = {}) {
        this.createHttpOptions(httpOptions);
        return this.httpClient.patch(url, data, this.httpOptions).pipe(
            map((res: any) => {
                if (res && res.status < 200 && res.status > 299) {
                    return observableThrowError(() => res.statusText);
                }
                return res;
            }),
            catchError(this.handleError.bind(this))
        );
    }

    private handleError(error: Response | any) {

        let resp;
        this.apiErrorMessage = resp;

        this.tk = window.localStorage.getItem('tk');
        this.jwtHelperService = new JwtHelperService();
        this.user = this.tk && this.jwtHelperService.decodeToken(this.tk);

        if (error && typeof (error.ok) === 'boolean' && !error.ok) {
            let mensajeError;
            if (error.code === 504 || error.code === 502) {
                mensajeError = 'Tiempo de espera agotado';
            } else {
                mensajeError = (error === 'unknown') ? 'Ups, algo salió mal.' : error;
            }
            resp = { status: { message: mensajeError } };
        } else if (error && !error.json && error.message) {
            resp = { status: { message: error.message } };
        } else {
            resp = (error && error.json) ? error.json() : error;
        }

        const analyticsData = {
            UserID: this.user.UserId,
            ErrorMessage: JSON.stringify(resp),
            RequestURL: error && error.url,
            RequestData: error && error._body,
            RequestHeader: JSON.stringify(error && error.headers)
        };

        this.analyticsService.logEvent('API_ERROR', analyticsData);

        return observableThrowError(() => resp.status.message);
    }

    private getHeaders(headersOptions = {}) {
        const baseHeadersOptions = { 'Authorization': 'Bearer ' + window.localStorage.getItem('tk') };
        let headers = new HttpHeaders(Object.assign({}, baseHeadersOptions, headersOptions));

        if (this.scBrowserDetectHelper.detect().name === 'ie') {
            headers = headers.append('Cache-control', 'no-cache');
            headers = headers.append('Pragma', 'no-cache');
            headers = headers.append('Expires', '0');
        }

        return headers;
    }

    private createHttpOptions(httpOptions: any = {}) {
        this.httpOptions = Object.assign({}, httpOptions, { headers: this.getHeaders(httpOptions.headers) });
    }

    private getUrlQueryParamsString(data): string {
        const params = new URLSearchParams();
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                params.set(key, data[key]);
            }
        }
        return params.toString();
    }
}
