import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'scVisualizationImages',
    pure: false
})
export class ScVisualizationImages implements PipeTransform {
    public assetsBasePath = '/assets/images/';
    transform(text: string): any {
        switch (text) {
            case'A':
                return this.assetsBasePath + 'header-illustration.png';
            case'B':
                return this.assetsBasePath + 'header-illustration-camion.png';
            case'C':
                return this.assetsBasePath + 'header-acoplado.png';
            case'D':
                return this.assetsBasePath + 'header-tractor.png';
            default:
                return '';
        }
    }
}
