import { ClaimPASFile } from './claimPASFile';

class ExpositionPASAutomotive {
  public adminEmail: string;
  public adminName: string;
  public claimOrder: number;
  public name: string;
  public stage: string;
  public status: string;

  constructor() {
    this.adminEmail = null;
    this.adminName = null;
    this.claimOrder = null;
    this.name = null;
    this.stage = null;
    this.status = null;
  }
}

export class ClaimPASDetailAutomotive {
  public claimDescription: string;
  public damages: Array<string>;
  public expositions: Array<ExpositionPASAutomotive>;
  public files: Array<ClaimPASFile>;
  public claimOrder: number;

  constructor() {
    this.claimDescription = '';
    this.damages = [];
    this.expositions = [];
    this.files = [];
    this.claimOrder = null;
  }
}
