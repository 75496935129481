import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

// Components
import { ScNotificationsComponent } from './sc-notifications.component';

// Modules
import { UiCoreComponentsModule } from '@sc/claim-components';
import { ScDropdownModule } from 'app/shared/components/sc-dropdown/sc-dropdown.module';
import { ScDirectivesModule } from 'app/shared/directives/sc-directives.module';

// Helpers
import { ScDateHelper } from 'app/core/helpers/sc-date.helper';

// Services
import { ScNotificationsService } from './sc-notifications.service';

// Pipes
import { ScPipesModule } from 'app/shared/pipes/sc-pipes.module';

@NgModule({
    imports: [
        CommonModule,
        UiCoreComponentsModule,
        ScDropdownModule,
        ScDirectivesModule,
        ScPipesModule
    ],
    exports: [
        ScNotificationsComponent
    ],
    declarations: [
        ScNotificationsComponent
    ],
    providers: [ScNotificationsService, ScDateHelper, DatePipe],
})
export class ScNotificationsModule { }
