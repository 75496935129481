import { Component } from '@angular/core';
import { Router } from '@angular/router';

// Services
import { AnalyticsService } from 'app/core/services/analytics.service';
import { TermMetricsService } from 'app/core/services/term-metrics.service';
import { AuthenticationService } from 'app/core/services';

// Environments
import { environment } from 'environments/environment';

@Component({
  selector: 'sc-app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  public favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  public routesWithNoHeader: Array<string>;
  public routesWithFooter: Array<string>;
  public showHeader: boolean;
  public showFooter: boolean;

  public showHeaderInfo: boolean;

  public userInfo;

  constructor(
    private router: Router,
    private tm: TermMetricsService,
    private analyticsService: AnalyticsService,
    private authenticationService: AuthenticationService
  ) {
    this.tm.initHJ();
    this.analyticsService.setGoogleAnalitycs();
    this.userInfo = this.authenticationService.getLocalStorageUserInfo();
  }

  updateHeaderVisibility() {
    this.userInfo = this.authenticationService.getLocalStorageUserInfo();
    if (this.userInfo.userRole === 'Administrador') {
      this.routesWithNoHeader = [
        '/authentication',
        '/admin',
        '/forbidden',
        '/error'
      ];
      this.routesWithFooter = [];
    } else if (this.userInfo.userRole === 'Productor') {
      this.routesWithNoHeader = [
        '/authentication',
        '/admin',
        '/error',
        '/forbidden',
        '/siniestros'
      ];
      this.routesWithFooter = ['/siniestros'];
    } else {
      this.routesWithNoHeader = [
        '/authentication',
        '/forbidden',
        '/error'
      ];
      this.routesWithFooter = ['/siniestros'];
    }
    if (this.router.url) {
      if (this.authenticationService.getAuthenticationToken()) {
        this.userInfo = this.authenticationService.getDecodedUserInfo();
      }
      // Header
      this.showHeader = this.isHeaderEnabled();
      this.showHeaderInfo = this.isHeaderInfoEnabled();
      // Footer
      this.showFooter = this.isFooterEnabled();
    }
  }

  isHeaderEnabled() {
    if (this.routesWithNoHeader && this.routesWithNoHeader.length > 0) {
      let isHeaderEnabled = true;
      for (let i = 0; i < this.routesWithNoHeader.length; i++) {
        if (this.router.url.indexOf(this.routesWithNoHeader[i]) !== -1) {
          isHeaderEnabled = false;
          break;
        }
      }
      return isHeaderEnabled;
    } else {
      return false;
    }
  }

  isHeaderInfoEnabled() {
    return this.showHeader && (environment.type.id === 'local' || environment.type.id === 'int' || environment.type.id === 'stag');
  }

  isFooterEnabled() {
    if (this.routesWithFooter && this.routesWithFooter.length > 0) {
      let isFooterEnabled = false;
      for (let i = 0; i < this.routesWithFooter.length; i++) {
        if (this.router.url.indexOf(this.routesWithFooter[i]) !== -1) {
          isFooterEnabled = true;
          break;
        }
      }
      return isFooterEnabled;
    } else {
      return false;
    }
  }

  getHeaderInfoState() {
    return (environment.type.id === 'local' || environment.type.id === 'int' || environment.type.id === 'stag');
  }

}
