import { NgModule } from '@angular/core';

import { ScAlphanumericPipe } from './sc-alphanumeric.pipe';
import { ScCategoryImages } from './sc-category-images';
import { ScCreditCardNumberPipe } from './sc-credit-card-number.pipe';
import { ScCurrencyPipe } from './sc-currency.pipe';
import { ScDatePipe } from './sc-date.pipe';
import { ScFileSizePipe } from './sc-file-size.pipe';
import { ScFilterPipe } from './sc-filter.pipe';
import { ScFirstLetters } from './sc-first-letters.pipe';
import { ScInverseCurrencyPipe } from './sc-inverse-currency.pipe';
import { ScNumberPipe } from './sc-number.pipe';
import { ScOperationStatusDisplayPipe } from './sc-operation-status-display.pipe';
import { ScPolicyStepPipe } from './sc-policy-step.pipe';
import { ScPrefixPipe } from './sc-prefix.pipe';
import { ScReversePipe } from './sc-reverse.pipe';
import { ScSafeDomPipe } from './sc-dom.pipe';
import { ScTruncatePipe } from './sc-truncate.pipe';
import { ScUpperWithFilterPipe } from './sc-upper-filter.pipe';
import { ScVisualizationImages } from './sc-visualizacion-images';
import { ScOrderByPipe } from './sc-order-by.pipe';
import { ScGroupByPipe } from './sc-group-by.pipe';

const pipes = [
    ScAlphanumericPipe,
    ScCategoryImages,
    ScCreditCardNumberPipe,
    ScCurrencyPipe,
    ScDatePipe,
    ScFileSizePipe,
    ScFilterPipe,
    ScFirstLetters,
    ScInverseCurrencyPipe,
    ScNumberPipe,
    ScOperationStatusDisplayPipe,
    ScPolicyStepPipe,
    ScPrefixPipe,
    ScReversePipe,
    ScSafeDomPipe,
    ScTruncatePipe,
    ScUpperWithFilterPipe,
    ScVisualizationImages,
    ScOrderByPipe,
    ScGroupByPipe
];

@NgModule({
    declarations: pipes,
    exports: pipes,
    providers: pipes
})
export class ScPipesModule {
}
