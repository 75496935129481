import { trigger, state, style, animate, transition } from '@angular/animations';

export const notificationAnimation = trigger('notificationAnimation', [
    state('void', style({
        'transform': 'scaleY(0)',
        'transform-origin': '100% 0',
        'opacity': 0
    })),
    state('true', style({
        'transform': 'scaleY(1)',
        'transform-origin': '100% 0',
        'opacity': 1
    })),
    transition('* <=> *', animate('100ms ease-in-out')),
]);
