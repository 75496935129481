import { Injectable } from '@angular/core';
import { ScDomHelper } from './sc-dom.helper';

@Injectable()
export class WindowScrolling {

    private styleTag: HTMLStyleElement;

    constructor(
        private dom: ScDomHelper
    ) {
        this.styleTag = this.buildStyleElement();
    }

    public disable(): void {
        this.setStyle(this.styleTag);
        document.body.appendChild(this.styleTag);
    }

    public enable(): void {
        document.body.removeChild(this.styleTag);
    }

    private buildStyleElement(): HTMLStyleElement {
        const style = document.createElement('style');

        style.setAttribute('data-debug', 'Injected by WindowScrolling service.');
        this.setStyle(style);

        return (style);
    }

    private setStyle(styleTag) {
        const hasVScroll = document.body.scrollHeight > document.body.clientHeight;
        styleTag.textContent = `body { overflow: hidden !important ; `;
        if (hasVScroll && !this.dom.isMobile()) {
            styleTag.textContent += `margin-right: 17px;`;
        }
        styleTag.textContent += ` }`;
    }

}
