import { trigger, state, style, animate, transition } from '@angular/animations';

export const dropdownAnimation = trigger('dropdownAnimation', [
    state('void', style({
        transform: 'scale(0)',
        opacity: 0
    })),
    state('bottom', style({
        'transform-origin': '50% 0',
        'transform': 'scale(1)'
    })),
    state(`bottom-left`, style({
        'transform-origin': '100% 0',
        'transform': 'scale(1)'
    })),
    state(`bottom-right`, style({
        'transform-origin': '0 0',
        'transform': 'scale(1)'
    })),
    state('top', style({
        'transform-origin': '50% 100%',
        'transform': 'scale(1)'
    })),
    state(`top-left`, style({
        'transform-origin': '100% 100%',
        'transform': 'scale(1)'
    })),
    state(`top-right`, style({
        'transform-origin': '0 100%',
        'transform': 'scale(1)'
    })),
    transition('* <=> *', animate('100ms ease-in-out')),
]);
