import { Component, Input } from '@angular/core';

@Component({
  selector: 'sc-empty-table-message',
  templateUrl: './sc-empty-table-message.component.html',
  styleUrls: ['./sc-empty-table-message.component.scss']
})
export class ScEmptyTableMessageComponent {

  @Input() title: string;
  @Input() message: string;
}
