import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  DoCheck
} from '@angular/core';

// Interface
import { DropdownConfig, Placement } from './sc-dropdown.interface';

// Directives
import { ScDropdownBtnItemDirective } from './sc-button-item.directive';
import { ScDropdownListDirective } from './sc-dropdown-list.directive';

// Animations
import { dropdownAnimation } from './sc-dropdown.animation';

@Component({
  selector: 'sc-dropdown',
  templateUrl: './sc-dropdown.component.html',
  styleUrls: ['./sc-dropdown.component.scss'],
  animations: [dropdownAnimation]
})
export class ScDropdownComponent implements DoCheck {

  @ViewChild('dropdown', { static: true }) dropdown;
  @ViewChild('dropdownMenu') dropdownMenu;

  @Input() public config: DropdownConfig;

  @Output() public onClick = new EventEmitter<boolean>();
  @Output() public onClickOutside = new EventEmitter<boolean>();
  @Output() public onOpen = new EventEmitter<boolean>();
  @Output() public onClose = new EventEmitter<boolean>();

  // Custom templates
  @ContentChild(ScDropdownBtnItemDirective, { read: TemplateRef }) buttonItemTemplate: TemplateRef<any>;
  @ContentChild(ScDropdownListDirective, { read: TemplateRef }) dropdownListTemplate: TemplateRef<any>;

  public placement: Placement;
  public canDropDown: boolean;
  public canDropRight: boolean;
  public _opened: boolean;

  @Input()
  get opened() {
    return this._opened;
  }
  set opened(value: boolean) {
    this._opened = value;
    this.setPlacement();
  }

  constructor() {
    this.opened = false;
    this.placement = 'bottom-left'; // Default
  }

  ngDoCheck() {
    const dropdownConfig = new DropdownConfig();
    const copyDropdownConfig = Object.assign({}, dropdownConfig, this.config);
    this.config = copyDropdownConfig;
  }

  setPlacement(): void {
    if (this.config) {
      if (this.config.placement === 'auto') {
        if (this._opened) {
          this.setTimeoutForDistances();
        }
      } else {
        this.placement = this.config.placement;
      }
    }
  }

  handleClick(event) {
    event.stopPropagation();
    this.opened = !this._opened;
    if (this.opened) {
      this.onOpen.emit(this._opened);
    }
    this.onClick.emit(this._opened);
  }

  handleClickOutside(event) {
    event.stopPropagation();
    this.handleClose();
    this.onClickOutside.emit(this._opened);
  }

  handleMenuItemClick(item, index?) {
    if (item.onClick) {
      item.onClick(item);
    }
    this.handleClose();
  }

  handleClose() {
    this.opened = false;
    this.onClose.emit(this.opened);
  }

  private getDropdownMenuHeight() {
    return this.dropdownMenu ? this.dropdownMenu.nativeElement.clientHeight : 0;
  }

  private getDropdownHeight() {
    return this.dropdown ? this.dropdown.nativeElement.clientHeight : 0;
  }

  private getDropdownMenuWidth() {
    return this.dropdownMenu ? this.dropdownMenu.nativeElement.clientWidth : 0;
  }

  private getDropdownWidth() {
    return this.dropdown ? this.dropdown.nativeElement.clientWidth : 0;
  }

  private getDistanceFromBottom() {
    const distanceFromWindowTop = this.dropdown ? this.dropdown.nativeElement.getBoundingClientRect().top : 0;
    return window.innerHeight - distanceFromWindowTop - this.getDropdownHeight();
  }

  private getDistanceFromLeft() {
    const distanceFromWindowLeft = this.dropdown ? this.dropdown.nativeElement.getBoundingClientRect().left : 0;
    return distanceFromWindowLeft + this.getDropdownWidth();
  }

  private setTimeoutForDistances() {
    setTimeout(() => {
      this.canDropDown = this.getDistanceFromBottom() >= this.getDropdownMenuHeight();
      this.canDropRight = this.getDropdownMenuWidth() >= this.getDistanceFromLeft();
      if (this.canDropDown) {
        this.placement = (this.canDropRight) ? 'bottom-right' : 'bottom-left';
      } else {
        this.placement = (this.canDropRight) ? 'top-right' : 'top-left';
      }
    }, 0);
  }

}
