export type Placement = 'bottom' | 'top' | 'bottom-right' | 'bottom-left' | 'top-left' | 'top-right' | 'auto';

export interface DropdownMenuItem {
    id?: string;
    label?: string;
    icon?: string;
    onClick?: Function;
}

export class DropdownBtn {
    id?: string;
    label?: string;
    type?: 'primary' | 'secondary' | 'link';
    disabled?: boolean;
    loading?: boolean;
    icon?: string;
    iconOnly?: boolean;
    suffixIcon?: string;
    size?: string;

    constructor() {
        this.id = null;
        this.label = null;
        this.type = 'link';
        this.disabled = false;
        this.loading = false;
        this.icon = null;
        this.iconOnly = false;
        this.suffixIcon = null;
        this.size = 'medium-fluid';
    }
}

export interface DropdownConfig {
    placement?: Placement;
    dropdownBtn: DropdownBtn;
    menu: Array<DropdownMenuItem>;
}

export class DropdownConfig implements DropdownConfig {

    placement?: Placement;
    dropdownBtn: DropdownBtn;
    menu: Array<DropdownMenuItem>;

    constructor() {
        this.placement = 'auto';
        this.dropdownBtn = new DropdownBtn();
        this.menu = [];
    }
}
