import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

// Services
import { ScNotificationsService } from './sc-notifications.service';
import { AuthenticationService } from 'app/core/services';

// Helpers
import { ScDateHelper } from 'app/core/helpers/sc-date.helper';

// Interfaces
import { Notification, NotificationAction } from './sc-notifications.interface';

// Animations
import { notificationAnimation } from './sc-notification.animation';

@Component({
    selector: 'sc-notifications',
    templateUrl: './sc-notifications.component.html',
    styleUrls: ['./sc-notifications.component.scss'],
    animations: [notificationAnimation]
})
export class ScNotificationsComponent implements OnInit, OnDestroy {

    @Input() public refreshOnOpen: boolean;
    @Input() public itemsPageSize: number;
    @Input() public timeRefresh: boolean;
    @Input() public time: number;

    public userName: string;

    public notifications: Notification[];
    public loadingNotifications: boolean;
    public unreadNotificationsCount: number;

    public filterParameters: any;
    public hasMoreItems: boolean;

    private notificationInterval: any;

    public isOpen: boolean;

    constructor(
        public scNotificationsService: ScNotificationsService,
        private authenticationService: AuthenticationService,
        private datePipe: DatePipe,
        public scDateHelper: ScDateHelper,
        public router: Router
    ) {
        this.notifications = [];
        this.loadingNotifications = false;
        this.unreadNotificationsCount = 0;

        this.filterParameters = {};
        this.hasMoreItems = true;
        this.itemsPageSize = 10;

        this.refreshOnOpen = false;
        this.timeRefresh = false;
        this.time = 600000; // 10 minutes

        this.isOpen = false;

        // override the route reuse strategy
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };

        this.router.events.subscribe((evt) => {
            if (evt instanceof NavigationEnd) {
                // trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
                window.scrollTo(0, 0);
            }
        });
    }

    ngOnInit() {
        this.userName = this.authenticationService.getUserName();
        this.resetNotificationsParameters();
        this.getUnreadNotificationsCount(this.userName);
        if (this.timeRefresh) {
            this.notificationInterval = setInterval(() => {
                this.getUnreadNotificationsCount(this.userName);
            }, this.time);
        }
    }

    ngOnDestroy () {
        clearInterval(this.notificationInterval);
    }

    getUnreadNotificationsCount(userName: string): void {
        if (userName) {
            this.scNotificationsService.getUnreadNotifications(this.userName).subscribe(
                (res) => {
                    // If we have new notifications refresh notifications
                    if (res !== this.unreadNotificationsCount) {
                        this.resetNotificationsParameters();
                        if (this.isOpen) {
                            this.getNotifications();
                        }
                    }
                    this.unreadNotificationsCount = res;
                }
            );
        }
    }

    _onDropdown(event): void {
        this.isOpen = event;
        if (this.refreshOnOpen) {
            this.getUnreadNotificationsCount(this.userName);
            this.getNotifications();
        } else {
            if (this.notifications.length === 0) {
                this.getNotifications();
            }
        }
    }

    _onNotificationClick(notification: Notification): void {
        if (notification.mainActionClick !== null && notification.mainActionClick !== '') {
            this.setNotificationAsReaded(notification);
            this.isOpen = false;
            this.router.navigate([notification.mainActionClick]);
        }
    }

    private resetNotificationsParameters(): void {
        this.notifications = [];
        this.hasMoreItems = true;
        this.filterParameters = {
            DestinationUserName: this.userName,
            pageSize: this.itemsPageSize
        };
    }

    private getNotifications(): void {
        this.loadingNotifications = true;
        this.scNotificationsService.getNotifications(this.filterParameters).subscribe({
            next: (res) => {
                res.items.map((item) => {
                    const dateDiff = this.getDiffDates(new Date(), item.notificationData.ReceptionDate);
                    item.dateGroup = (dateDiff <= 3) ? 'ÚLTIMOS DÍAS' : 'ANTES';
                    return item;
                });
                this.notifications.push(...res.items);
                this.filterParameters.continuationToken = encodeURIComponent(res.continuationToken);
                this.hasMoreItems = res.hasMoreItems;
                this.loadingNotifications = false;
            },
            error: () => {
                this.loadingNotifications = false;
            }
        });
    }

    private _onNotificationActionClick(notification: Notification, action: NotificationAction): void {
        this.scNotificationsService.setNotificationAsReaded(notification.id).subscribe(
            (res) => {
                this.setNotificationAsReaded(notification);
                if (action.actionClick) {
                    this.router.navigate([action.actionClick]);
                }
            }
        );
    }

    private setNotificationAsReaded(notification: Notification): void {
        if (notification) {
            this.scNotificationsService.setNotificationAsReaded(notification.id).subscribe(
                (res) => {
                    this.getUnreadNotificationsCount(this.userName);
                }
            );
        }
    }

    public getDateLabel(date) {
        const diffDates = this.getDiffDates(new Date(), new Date(date));
        if (diffDates === 0) {
            return 'Hoy';
        } else if (diffDates === 1) {
            return 'Ayer';
        } else {
            return this.datePipe.transform(date, 'dd/MM/yy');
        }
    }

    public getDiffDates(date1: Date, date2: Date): number {
        return this.scDateHelper.diffDates(date1, date2);
    }
}
