import { trigger, transition, animate, style } from '@angular/animations';

export const slideFadeSlowAnimation = trigger('slideFadeSlowAnimation', [
    transition(':enter', [
        style({ opacity: 0, transform: 'translateY(50px)' }),
        animate('500ms ease-in-out', style({ opacity: 1, transform: 'translateY(0px)' }))
    ]),
    transition(':leave', [
        style({ opacity: 1, transform: 'translateY(0px)' }),
        animate('300ms 500ms ease-in-out', style({ opacity: 0, transform: 'translateY(50px)' }))
    ])
]);
