export class Risk {
    public brand: string;
    public model: string;
    public plateNumber: string;
    public version: string;
    public year: number;

    constructor() {
        this.brand = '';
        this.model = '';
        this.plateNumber = '';
        this.version = '';
        this.year = 0;
    }
}
