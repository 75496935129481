import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ManagementTracking, ServiceOrdersByClaimNumberResponse, SparePartsResponse, CardInfo, ClaimEventsTarget } from 'app/shared/models';
import { environment } from 'environments/environment';
import { Observable, catchError, map, of, throwError } from 'rxjs';

@Injectable()
export class ClaimApi {
  private token: string;

  constructor(private httpClient: HttpClient) {
    this.token = window.localStorage.getItem('tk');
  }

  getClaimEvents(claimId: string, isAutomotive: boolean): Observable<ManagementTracking> {
    return this.httpClient
      .get<ManagementTracking>(
        `${environment.apiClaimV2}/claimEvents/${claimId}?target=${isAutomotive ? ClaimEventsTarget.Auto : ClaimEventsTarget.OORR}`,
        this.getHeaders()
      )
      .pipe(this.getCatchErrorOperator());
  }

  getServiceOrders(claimNumber: string): Observable<SparePartsResponse> {
    return this.httpClient
      .get<ServiceOrdersByClaimNumberResponse>(`${environment.apiClaimV2}/claims/${claimNumber}/service-orders`, this.getHeaders())
      .pipe(
        map((response) => ({
          hasToShow: !!response.serviceOrders.length,
          serviceOrders: response.serviceOrders,
          specialistVendors: response.specialistVendors
        })),
        this.getCatchErrorOperator()
      );
  }

  private getHeaders() {
    return { headers: { Authorization: `Bearer ${this.token}` } };
  }

  private getCatchErrorOperator<T>() {
    return catchError<T, Observable<T>>((value: HttpErrorResponse) => {
      if (value.status === HttpStatusCode.NotFound) {
        return of(null);
      }

      return throwError(() => value);
    });
  }

  getCardInfo(claimId: string): Observable<CardInfo> {
    return this.httpClient.get<CardInfo>(`${environment.apiClaimV2}/claims/${claimId}/card-info`, this.getHeaders());
  }
}
