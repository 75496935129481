import { Person } from './person';

export class Insured extends Person {

  public businessName: string;

  constructor() {
    super();
    this.businessName = '';
  }
}
