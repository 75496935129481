import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Directives
import { ScClickOutsideDirective } from './sc-click-outside.directive';
import { ScScrollDirective } from './sc-scroll.directive';
import { ClickOutsideDirective } from '../components/sc-filter-pas/click-out-side.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ScClickOutsideDirective,
    ScScrollDirective,
    ClickOutsideDirective
  ],
  exports: [
    ScClickOutsideDirective,
    ScScrollDirective,
    ClickOutsideDirective
  ]
})
export class ScDirectivesModule { }
